import Toggle from "components/Toggle";
import useFetch from "hooks/useFetch";
import React, { useState } from "react";
import { Container, Card, Button, CardHeader, CardBody, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Pagination, PaginationItem, PaginationLink, CardFooter } from "reactstrap"
import { api } from "services/api";
import InventoryLocationDetailModal from "./inventoryLocationDetailModal";
import CModal from "../../../components/Modal/ConfirmationModal";
import { useToasts } from "react-toast-notifications";

const setListingPagePagination = (count, page, setPagination) => {
    const totalPages = Math.ceil(count / window.env.PAGE_SIZE);
    const previous = (page !== 1) ? page - 1 : 1
    const next = (page !== totalPages) ? page + 1 : totalPages
    setPagination({
        previous, next,
        pages: () => {
            // Return page list count array
            // Used to display number of pages in pagination
            let startCount = 1;
            let endCount = 12;
            let numberCount = Math.round(endCount / 2)
            const pageList = [];
            if (numberCount < 0) { numberCount = 1 }
            startCount = page - numberCount
            if (startCount <= 0) { startCount = 1 }
            if (page !== 1) { endCount = page + numberCount }
            if (totalPages >= endCount) { for (let i = startCount; i <= endCount; i++) { pageList.push(i) } }
            else if (totalPages >= 1) { for (let i = 1; i <= totalPages; i++) { pageList.push(i) } }
            return pageList;
        }
    })
}

const SettingsInventoryLocation = () => {
    const { addToast } = useToasts();

    // Use ststes
    // mode = add or update
    const [showInventoryLocationModel, setshowInventoryLocationModel] = useState({ show: false, mode: "", data: null, inventoryLocationTypes: null });
    const [deleteInventoryLocationId, setdeleteInventoryLocationId] = useState(null);
    const [showConfirmationModal, setshowConfirmationModal] = useState(false);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({ previous: null, next: null, pages: () => { return [] } });

    // useEffects
    const [states] = useFetch({ url: "settings/inventory-location-states-list/" });
    const [inventoryLocationTypes] = useFetch({ url: "settings/inventory-location-types-list/" });
    const [inventoryLocation, getInventoryLocationError, getInventoryLocationIsLoading, refetchInventoryLocation] =
        useFetch({ url: "settings/inventory-location-list/", initialData: [], page: page });
    React.useEffect(() => { if (inventoryLocation) setListingPagePagination(inventoryLocation.count, page, setPagination) }, [states, inventoryLocation]);
    React.useEffect(() => { refetchInventoryLocation(); }, [page]);

    // Functions
    const toggleAutoAssign = async (index) => {
        await api.put("settings/inventory-location-list/",
            { "is_auto_assignable": !inventoryLocation.results[index]["is_auto_assignable"] },
            { params: { inventory_location_id: inventoryLocation.results[index]["id"] } })
            .then((response) => response)
            .then((response) => {
                if (response.status === 200) {
                    addToast(response.data.message, { appearance: "success", autoDismiss: true });
                    refetchInventoryLocation();
                } else {
                    let message = "Something went wrong!";
                    if (response?.data?.message) { message = response.data.message; };
                    addToast(message, { appearance: "error", autoDismiss: true });
                }
            });
    };

    const deleteInventoryLocation = async () => {
        await api.delete(`settings/inventory-location-list?inventory_location_id=${deleteInventoryLocationId}`, {})
            .then((response) => response)
            .then((response) => {
                if (response.status === 200) {
                    addToast(response.data.message, { appearance: "success", autoDismiss: true });
                    refetchInventoryLocation();
                } else {
                    let message = "Something went wrong!";
                    if (response?.data?.message) { message = response.data.message; };
                    addToast(message, { appearance: "error", autoDismiss: true });
                }
            })
        setdeleteInventoryLocationId(null);
    };

    const getStateNameFromStateId = (stateId) => { 
        const stateName = states?.data.filter((state) => state.value === stateId)[0]
        return stateName ? stateName.label : "-" 
    };

    const InventoryLocationTable = () => {
        return (
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <th scope="col">Full Name</th>
                    <th scope="col">Short Name</th>
                    <th scope="col">Code</th>
                    <th scope="col">State</th>
                    <th scope="col">Auto Assign</th>
                    <th scope="col"></th>
                </thead>
                <tbody>
                    {inventoryLocation?.results?.map((inventoryLocationDetail, index) =>
                        <tr key={inventoryLocationDetail.id}>
                            <td className="pl-3 pr-3">{inventoryLocationDetail.full_name}</td>
                            <td className="pl-3 pr-3">{inventoryLocationDetail.name}</td>
                            <td className="pl-3 pr-3">{inventoryLocationDetail.code}</td>
                            <td className="pl-3 pr-3">{getStateNameFromStateId(inventoryLocationDetail?.state)}</td>
                            <td className="pl-3 pr-3">
                                <Toggle
                                    checked={inventoryLocationDetail?.is_auto_assignable}
                                    onChange={() => { toggleAutoAssign(index) }}
                                />
                            </td>
                            <td className="text-right">
                                <UncontrolledDropdown>
                                    <DropdownToggle className="btn text-primary" role="button" size="sm" color="white" onClick={(e) => e.preventDefault()}>
                                        <i className="fas fa-ellipsis-v text-dark"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right positionFixed={true} className="dropdown-menu-arrow"
                                        modifiers={{ preventOverflow: { boundariesElement: 'window' } }}>
                                        <DropdownItem onClick={() => {
                                            setshowInventoryLocationModel({ show: true, mode: "update", data: inventoryLocationDetail });
                                        }}>
                                            <i className=" fas fa-edit fa-fw" /> Edit </DropdownItem>
                                        <DropdownItem onClick={() => {
                                            setshowConfirmationModal(true);
                                            setdeleteInventoryLocationId(inventoryLocationDetail.id);
                                        }}>
                                            {/* Monday Task: 6225127393 - Barcart Retailer Cleanup Created "Archvied at" for inventory location */}
                                            <i className=" fas tex-red fa-trash fa-fw" /> Archive </DropdownItem> 
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        )
    };

    return (
        <>
            {/* Models */}
            {showConfirmationModal &&
                <CModal
                    modelIsOpen={showConfirmationModal}
                    setmodelIsOpen={setshowConfirmationModal}
                    modalBodyMessage="Are you sure, you want to delete this Inventory Location ?"
                    onConfirmation={deleteInventoryLocation}
                />
            }
            {showInventoryLocationModel.show &&
                <InventoryLocationDetailModal
                    data={showInventoryLocationModel.data}
                    mode={showInventoryLocationModel.mode}
                    showInventoryLocationModel={showInventoryLocationModel}
                    setshowInventoryLocationModel={setshowInventoryLocationModel}
                    refetchInventoryLocation={refetchInventoryLocation}
                    states={states.data}
                    inventoryLocationTypes={inventoryLocationTypes}
                />
            }

            <Container fluid className={"pt-7"}>
                <Card className="shadow">
                    <CardHeader className="bg-transparent d-flex justify-content-between">
                        <h3 className="mb-0">Inventory Locations <span>({inventoryLocation?.count})</span></h3>
                        <Button
                            size='sm'
                            color='primary'
                            className='px-2 pr-3'
                            onClick={() => {
                                setshowInventoryLocationModel({ ...showConfirmationModal, mode: "add", show: true, data: null })
                            }}>
                            <i className='ml-1 fas fa-plus' />
                            &nbsp; Add
                        </Button>
                    </CardHeader>
                    <CardBody>
                        {getInventoryLocationIsLoading ?
                            <div className="text-center mt-3 mb-3">
                                <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>
                            </div>
                            : <InventoryLocationTable />}
                    </CardBody>
                </Card>

                <CardFooter className="text-center">
                    <nav aria-label="...">
                        {pagination.pages().length > 1 && <Pagination
                            className="pagination justify-content-center mb-0"
                            listClassName="justify-content-center mb-0"
                        >
                            <PaginationItem className="">
                                <PaginationLink href="#" onClick={() => { setPage(pagination?.previous) }} tabIndex="-1">
                                    <i className="fas fa-angle-left" /><span className="sr-only">Previous</span>
                                </PaginationLink>
                            </PaginationItem>
                            {pagination.pages().map((pageIndex, index) => (
                                <PaginationItem key={index} className={(page === pageIndex) ? "active" : ""}>
                                    <PaginationLink href="#" onClick={() => { setPage(pageIndex) }}> {pageIndex} </PaginationLink>
                                </PaginationItem>
                            )
                            )}
                            <PaginationItem>
                                <PaginationLink href="#" onClick={() => { setPage(pagination?.next) }}>
                                    <i className="fas fa-angle-right" /> <span className="sr-only">Next</span>
                                </PaginationLink>
                            </PaginationItem>
                        </Pagination>}
                    </nav>
                </CardFooter>
            </Container>
        </>
    )
}

export default SettingsInventoryLocation;