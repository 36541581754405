import React, { useEffect, useState,useContext } from "react";
import { Table, Card, CardHeader, Col, Container, Row, CardFooter, Pagination, PaginationItem, PaginationLink,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Button,
} from "reactstrap";
import { api } from "services/api";
import Filter from "../../components/Filters/Filter";
import BadgePillFilter from "components/Filters/Badge";
import { useToasts } from "react-toast-notifications";
import Moment from "react-moment";
import moment from "moment";
import fileDownload from "js-file-download";
import PagePagination from "feature/pagination/Pagination";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import { Sort, clearFilter, getParams, handleSort, onFilterChange, removeBadge } from "../../utils/commonFunctions";
import useFilter from "customHooks/useFilter";
import { useLocation } from "react-router-dom";
import { processURLSearchParams } from "utils/urls";
import { removeAllURLParams } from "utils/urls";
import { removeURLParam } from "utils/urls";
import { prefixAndFilterMapping } from "customHooks/useFilter";
import { AuthContext } from "contexts/AuthContext";

function InventoryListing(props) {
  const { user} = useContext(AuthContext);
  const [sort, setSort] = useState("id");
  const [sortOrder, setSortOrder] = useState({order: "", columnName: ""});
  const [products, setProducts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [totalProducts, setTotalProducts] = useState(0);
  const [page, setPage] = useState(1);
  const [filter, setFilter, clearFilterState] = useFilter({ endDate: null, startDate: null })
  const [params, setParams] = useState([]);
  const [selected, setSelected] = useState([]);
  const [clearall, setClearAll] = useState(false)
  const [pagination, setPagination] = useState({
    previous: null,
    next: null,
    pages: () => {
      return []
    }
  });
  const [refresh, setRefresh] = useState(false);
  const [exportButton, setExportButton] = useState({
    label: "Export",
    disabled: false,
  });
  const [deleteInventoryRecordId, setdeleteInventoryRecordId] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState();
  const [cellInputToggle,setCellInputToggle] = useState({});
  const { addToast } = useToasts();

  // Check weather screen size is mobile or desktop
  const isMobile = useMobileDetection(mobileScreenThresholdValue);

  const toggle = () => {
    setOpenDeleteModal(!openDeleteModal);
  };
  const searchInput = document.getElementById("productInventorySearch")
  /**
   * Refresh the selected filter 
   */
   const handleRefresh = () => {
    setRefresh(!refresh);
  };

  // Method to call the delete API with the id of the record to be deleted as parameter
  const deleteInventoryMappingRecord = (inventoryRecordId) => {
    api
      .delete(
        `unlink-inventory-record?inventory_record_id=${inventoryRecordId}`,
        {}
      )
      .then((response) => response)
      .then((response) => {
        if (response.status === 200) {
          addToast(response.data.message, {
            appearance: "success",
            autoDismiss: true,
          });
          handleRefresh();
          setOpenDeleteModal(false);
        } else {
          let message = "Something went wrong!";

          if (response?.data?.message) {
            message = response.data.message;
          }

          addToast(message, {
            appearance: "error",
            autoDismiss: true,
          });
          handleRefresh();
          setOpenDeleteModal(false);
        }
      })
      .catch((err) => {
        addToast("Something went wrong, please try again!!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  // API call to get the Product inventory data
  useEffect(() => {
    setLoading(true);
    let new_params = getParams(filter, setClearAll);
    setParams(new_params);
    new_params = {
      ...new_params,
      page,
      sorted: sort,
      page_size: window.env.PAGE_SIZE,
    }

    api
      .get("/product-inventory", {
        params: new_params
      })
      .then((response) => {
        setProducts(response.data.data);
        setTotalProducts(response.data.count);

        const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
        const previous = (page !== 1) ? page - 1 : 1;
        const next = (page !== totalPages) ? page + 1 : totalPages;
        setPagination({
          previous,
          next,
          pages: () => {

            let startCount = 1;
            let endCount = isMobile? 4: 12;
            let numberCount = Math.round(endCount / 2);
            const pageList = [];
            if (numberCount < 0) numberCount = 1;
            startCount = page - numberCount
            if (startCount <= 0) startCount = 1;
            if (page !== 1) endCount = page + numberCount;
            if (endCount > totalPages) endCount = totalPages;
            if (totalPages >= endCount) {
              for (let i = startCount; i <= endCount; i++) {
                pageList.push(i)
              }
            } else if (totalPages >= 1) {
              for (let i = 1; i <= totalPages; i++) {
                pageList.push(i)
              }
            }

            return pageList;
          }
        })
        setLoading(false);
        delete new_params['page'];
        delete new_params['page_size'];
        delete new_params['sorted'];
        setParams(new_params);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [page, sort, filter, refresh]);

  /**
  * Set the value of page
  * @param {*} page set updated page value
  */
  const handlePagination = page => {
    setPage(page);
  };

  // Set the search value
  let timer = null;
  const handleSearch = (e) => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      setFilter({
        ...filter,
        search: e.target.value
      })
    }, 750);
  };

    /**
   * 
   * @param {*} e set the inventoryProduct = Id and value for filtering 
   */
     const handleProductName = (e) => {
      let checkbox = [...new Set(filter.productNames)];
  
      if (e.target.checked) {
        checkbox.push({ value: e.target.value, label: e.target.dataset.label });
      } else {
        checkbox = checkbox.filter((item) => item.value !== e.target.value);
      }
  
      setFilter({ ...filter, productNames: checkbox });
    };
  
  /**
   * 
   * @param {*} e set the inventoryLocationId for filtering
   */
    const handleLocationId = (e) => {
      let checkbox = [...new Set(filter.locationIds)];
  
      if (e.target.checked) {
        checkbox.push({ value: e.target.value, label: e.target.dataset.label });
      } else {
        checkbox = checkbox.filter((item) => item.value !== e.target.value);
      }
  
      setFilter({ ...filter, locationIds: checkbox });
    };
  
    const handleBrandId = (e) => {
      let checkbox = [...new Set(filter.brands)];
      if (e.target.checked) {
        checkbox.push({ value: e.target.value, label: e.target.dataset.label });
      } else {
        checkbox = checkbox.filter((item) => item.value !== e.target.value);
      }
  
      setFilter({ ...filter, brands: checkbox });
    };

    const removeBadgee = (e) => {

      if (e.component === "productNames") {
        let items = filter.productNames;
        items = items.filter((item) => item.value !== e.item.value);
        setFilter({ ...filter, productNames: items });
      } else if (e.component === "brand") {
        let items = filter.brands;
        items = items.filter((item) => item.value !== e.item.value);
        setFilter({ ...filter, brands: items });
      }else if (e.component === "locationIds") {
        let items = filter.locationIds;
        items = items.filter((item) => item.value !== e.item.value);
        setFilter({ ...filter, locationIds: items });
      } 
      setClearAll(false);
    };
  
    const handleClearAll = () => {
      setClearAll(false);
      setSelected([]);
      searchInput.value = "";
      removeAllURLParams()
      clearFilterState();
    };

    const DeleteInventoryMapping = (
      id,
    ) => {
      setOpenDeleteModal(true);
      setdeleteInventoryRecordId(id);
    };

    const handleExportProductInventory = () => {
    let new_params = params;

    setExportButton({
        label: "In progress",
        disabled: true,
    });

    api.get('product-inventory/export/', {
        params: new_params
    })
        .then(response => response.data)
        .then(response => {
            const success = response?.success | false;

            if (success) {
                addToast(response.message,
                    {appearance: 'success', autoDismiss: false});
            } else {
                const timestamp = moment().format('MMMM-Do-YYYY-h:mm:ss-a');
                const filename = 'barcartInventory' + timestamp + '.csv'
                fileDownload(response, filename);
            }

            setExportButton({
                label: "Export",
                disabled: false,
            });
        })
    }

const toggleCellInput = (id) => {
  setCellInputToggle((prevState) => ({
  [id]: !prevState[id],
  }));
};
  
  const handleCellInputBlur = (event, id, minimum_qty) => {
    let quantity = event.target.value === "" ? 0 : parseInt(event.target.value);
    setCellInputToggle((prevState) => ({
      [id]: !prevState[id],
    }));

    if (minimum_qty !== parseInt(event.target.value)) {
      api
        .put("/availability/variant/minimum_quantity/", {
          id: id,
          minimum_quantity: quantity,
        })
        .then((response) => {
          if (response.status === 200) {
            setRefresh(!refresh)
            addToast("Minimum Quantity Updated", {
              appearance: "success",
              autoDismiss: true,
            });

          } else {
            addToast("Something Went Wrong", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

  }

const onKeyDownHandler = (event,id,minimum_qty)=>{
  if(event.key === "Enter"){
    if(minimum_qty !== parseInt(event.target.value)){
      handleCellInputBlur(event,id,minimum_qty)
    }
  }
}

  const renderConditionalCell = (id, minimum_qty) => {
    return (
      <td key={id}>
        {
          user && user.isAdmin ?
            <React.Fragment>
              {!cellInputToggle[id] ? <Button
                onClick={(event) => toggleCellInput(id)}
                className="text-center"
                outline={true}
                color="secondary"
                id={"button--" + id}
                style={{ "border": 0, "width": "100%",paddingTop:"4.4px",paddingBottom:"4.4px" }}>
                {minimum_qty || 0}
              </Button> :
                <Input
                  step="1"
                  min="0"
                  onBlur={(event) => handleCellInputBlur(event, id, minimum_qty)}
                  onKeyDown={(e) => onKeyDownHandler(e, id, minimum_qty)}
                  className="text-center" id={"input--" + id}
                  style={{ width: "100%"}}
                  bsSize="sm"
                  type="number"
                  defaultValue={minimum_qty || 0}
                >
                </Input>}
            </React.Fragment> : minimum_qty
        }
      </td>)

  }

  /**
   * Function used for displaying the inventory statuses and it's color based on the values coming from backend
   * @param {*} inventoryStatus 
   * @returns 
   */
  const getStatusTextAndColor = (inventoryStatus) => {
    switch (inventoryStatus) {
      case 'IN_STOCK':
        return <span className="text-success">In Stock</span>;
      case 'LOW_STOCK':
        return <span className="text-warning">Low Stock</span>;
      case 'OUT_OF_STOCK':
        return <span className="text-danger">Out of Stock</span>;
      default:
        return <span className="text-muted">-</span>;
    }
  };

    const urlSearchParams = useLocation().search;
    React.useMemo(() => {
        processURLSearchParams(new URLSearchParams(urlSearchParams), filter, setFilter);
    }, [urlSearchParams]);

  /**
  * Function for doing operation after onclick of quantity button
  * @param {*} event 
  * @param {*} variant 
  * @param {*} location 
  */
  const handleCellButtonClick = (event, variant, location) => {
    event.preventDefault();
    document.getElementById("button--" + variant + "--" + location).classList.toggle("invisible")
    document.getElementById("button--" + variant + "--" + location).classList.toggle("d-none")
    document.getElementById("input--" + variant + "--" + location).value = parseInt(document.getElementById("button--" + variant + "--" + location).textContent)
    document.getElementById("input--" + variant + "--" + location).classList.toggle("invisible")
    document.getElementById("input--" + variant + "--" + location).classList.toggle("d-none")
  }

  /**
   * Function to apply style for button
   * @param {*} id 
   * @param {*} quantity 
   */
  const decorateCellButton = (id, quantity) => {
    let _element = document.getElementById(id)
    _element.classList.remove("btn-outline-secondary", "btn-outline-warning", "btn-outline-danger")
    let _color = 6 > quantity ? "danger" : 12 > quantity && 6 <= quantity ? "warning" : "secondary"
    _element.classList.add("btn-outline-" + _color)
  }

  /**
   * Function for Put API request if user changes the quantity's value
   * @param {*} event 
   * @param {*} variant 
   * @param {*} location 
   */
  const handleCellInputBlurQuantity = (event, variant, location) => {
    event.preventDefault();
    (async () => {
      try {
        let _quantity = document.getElementById("input--" + variant + "--" + location).value
        let _response = await api.put(`availability/variant/${variant}/inventory/${location}/`, {
          variant: variant,
          inventory: location,
          value: _quantity,
        })
        decorateCellButton("button--" + variant + "--" + location, parseInt(_quantity))
        addToast('Inventory quantity updated Successfully.', { appearance: 'success', autoDismiss: true });
      } catch (_error) {
        addToast('Unable to update inventory quantity.', { appearance: 'error', autoDismiss: true });
      }
    })();
    document.getElementById("button--" + variant + "--" + location).textContent = document.getElementById("input--" + variant + "--" + location).value
    document.getElementById("button--" + variant + "--" + location).classList.toggle("invisible")
    document.getElementById("button--" + variant + "--" + location).classList.toggle("d-none")
    document.getElementById("input--" + variant + "--" + location).classList.toggle("invisible")
    document.getElementById("input--" + variant + "--" + location).classList.toggle("d-none")
  }

  /** This function is used for 
   * Function to render Quantity button
   * @param {*} id 
   * @param {*} quantity 
   * @param {*} variantId 
   * @param {*} locationId 
   * @returns 
   */
  const renderConditionalCellQuantity = (id, quantity, variantId, locationId) => {
    return (
      <td key={id}>
        {
          user.isAdmin || user.isRetailer ?
            <React.Fragment>
              <Button
                onClick={(event) => handleCellButtonClick(event, variantId, locationId)}
                className="text-center"
                outline={true}
                color={6 > quantity ? "danger" : 12 > quantity && 6 <= quantity ? "warning" : "secondary"}
                id={"button--" + variantId + "--" + locationId}
                style={{ "border": 0, "width": "3vw" }}>
                {quantity}
              </Button>
              <Input
                max="999"
                min="0"
                step="1"
                onBlur={(event) => handleCellInputBlurQuantity(event, variantId, locationId)}
                className="d-none invisible text-center" id={"input--" + variantId + "--" + locationId}
                style={{ width: "3vw" }}
                bsSize="sm" type="number"
                defaultValue={quantity}
              >
              </Input>
            </React.Fragment>
            :
            quantity
        }
      </td>)
  }

  return (
    <>
    <Container className="pt-7" fluid>
      <Row>
        <div className="col">
          <Row className={"mb-2"}>
            <Col xs={10} lg={"3"} className={"text-left"}>
              <input type="text"
                className={"form-control form-control-sm"}
                placeholder={"Product Name or SKU or Retailer SKU"}
                id="productInventorySearch"
                defaultValue={filter.search}
                onKeyDown={handleSearch}
                newparams={""}
              />
            </Col>
            <Col className={"text-right"}>
              <div className={"d-none d-lg-inline-block mr-2"}>
                <button
                  className="btn btn-neutral btn-sm p-9px"
                  onClick={handleRefresh}
                >
                  <i className="fas fa-sync-alt"></i>
                </button>

                <Filter
                  name={"Product Name"}
                  prefix={"productNames"}
                  onChange={(e) =>{ onFilterChange(e, "productNames", filter, setFilter) }}
                  newparams={params}
                  selected={filter.productNames}
                  url={"/inventory/filter/product"}
                  onClear={() => clearFilter("productNames", setFilter, setClearAll)}
                />

                <Filter
                  name={"Location Name"}
                  prefix={"locationIds"}
                  onChange={(e) =>{ onFilterChange(e, "locationIds", filter, setFilter) }}
                  newparams={params}
                  selected={filter.locationIds}
                  url={"/inventory/filter/location"}
                  onClear={() => clearFilter("locationIds", setFilter, setClearAll)}
                />

                <Filter
                  name={"Brand"}
                  prefix={"brand"}
                  onChange={(e) =>{ onFilterChange(e, "brand", filter, setFilter) }}
                  newparams={params}
                  selected={filter.brands}
                  url={"/order/filter/brand/"}
                  onClear={() => clearFilter("brand", setFilter, setClearAll)}
                />

               <Filter
                  name={"Inventory Status"}
                  prefix={"inventoryStatus"}
                  onChange={(e) =>{ onFilterChange(e, "inventoryStatus", filter, setFilter) }}
                  newparams={params}
                  selected={filter.inventoryStatus}
                  url={"inventory/filter/inventory-status"}
                  onClear={() => clearFilter("inventoryStatus", setFilter, setClearAll)}
                />

                <Button
                  color={"primary"}
                  size={"sm"}
                  className="p-10px"
                  onClick={handleExportProductInventory}
                  disabled={exportButton.disabled}>
                  <i className="fas fa-file-csv mr-2"></i> {exportButton.label}
                </Button>

              </div>
            </Col>
          </Row>
          <Card className="shadow ">
            <CardHeader className="border-0">
              <Row>
                <Col>
                  <h3 className="mb-0"> Inventory <small>({totalProducts})</small> </h3>
                  {clearall && (
                    <a
                      href={void 0}
                      className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                      onClick={handleClearAll}
                      id="clear_all_filters"
                    >
                      <i className="fas fa-times-circle"></i> Clear All
                      filters
                    </a>
                  )}
                </Col>
                <Col sm="12">
                  <BadgePillFilter
                    data={filter}
                    selected={selected}
                    onClick={(e)=>{ removeBadge(e, filter, setFilter, selected, setSelected,() => {}, searchInput) }}
                  />
                </Col>
              </Row>
            </CardHeader>
            {isLoading ?
              <div className="text-center mt-3 mb-3">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div> :
              <>
                <Table className="align-items-center table-flush" responsive>
                  <thead className=" thead-light">
                    <tr>
                      <th scope="col" className="pl-3 pr-3">
                        <Sort onClick={() =>
                          handleSort(sort,"variant__product__name", setSortOrder, setSort)}
                          sortingOrder={sortOrder}
                          column="variant__product__name"
                          >
                          Product Name
                        </Sort>
                      </th>
                      <th scope="col" className="pl-3 pr-3"> <Sort onClick={() =>
                        handleSort(sort,"variant__sku", setSortOrder, setSort)} sortingOrder={sortOrder} column="variant__sku"> Flaviar Checkout SKU </Sort> </th>
                      <th scope="col" className="pl-3 pr-3"> <Sort onClick={() =>
                        handleSort(sort,"retailer_variant_sku", setSortOrder, setSort)} sortingOrder={sortOrder} column="retailer_variant_sku"> Retailer SKU </Sort> </th>
                      <th scope="col" className="pl-3 pr-3">Brand UPC </th>
                      <th scope="col" className="pl-3 pr-3"> <Sort onClick={() =>
                        handleSort(sort,"inventory__full_name", setSortOrder, setSort)} sortingOrder={sortOrder} column="inventory__full_name"> Location Name </Sort> </th>
                      <th scope="col" className="pl-3 pr-3"> State </th>
                      <th scope="col" className="pl-3 pr-3"> <Sort onClick={() =>
                        handleSort(sort,"value", setSortOrder, setSort)} sortingOrder={sortOrder} column="value"> Quantity </Sort> </th>
                      <th scope="col" className="pl-3 pr-3"> <Sort onClick={() =>
                        handleSort(sort,"minimum_quantity", setSortOrder, setSort)} sortingOrder={sortOrder} column="minimum_quantity"> Minimum Quantity </Sort> </th>
                      <th scope="col" className="pl-3 pr-3"> Last 30 days </th>
                      <th scope="col" className="pl-3 pr-3"> <Sort onClick={() =>
                        handleSort(sort,"variant__price", setSortOrder, setSort)} sortingOrder={sortOrder} column="variant__price"> Price </Sort> </th>
                      <th scope="col" className="pl-3 pr-3"> Retailer Price </th>
                      <th scope="col" className="pl-3 pr-3"> Delta  </th>
                      <th scope="col" className="pl-3 pr-3"> Retailer UPC </th>
                      <th scope="col" className="pl-3 pr-3"> Updated At </th>
                      <th scope="col" className="pl-3 pr-3"> Inventory Status </th>
                      <th scope="col" className="pl-3 pr-3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((product, index) => (
                      <tr key={index}>
                        <td className="pl-3 pr-3">
                          {product.product_name} &nbsp; &nbsp;
                          <a href={`orders/?productName=${product.product_name}`} target="_blank" rel="noreferrer">
                              <i class="fas fa-history" aria-hidden="true"> </i> 
                          </a>
                        </td>
                        <td className="pl-3 pr-3">{product.barcart_sku}</td>
                        <td className="pl-3 pr-3">{product.retailer_sku}</td>
                        <td className="pl-3 pr-3 text-center">{product.upc ?? "-"}</td>
                        <td className="pl-3 pr-3">{product.location_name}</td>
                        <td className="pl-3 pr-3">{product.state}</td>
                        {renderConditionalCellQuantity(product.id, product.quantity, product.variant_id, product.inventory_id)}
                        {renderConditionalCell(product.id,product.minimum_quantity)}
                        <td className="pl-3 pr-3">{product.total_items_sold}</td>
                        <td className="pl-3 pr-3">{product.price}</td>
                        <td className="pl-3 pr-3">{product.retailer_price}</td>
                        <td className={`pl-3 pr-3 ${product?.delta ? product?.delta > 0 ? "text-success" : "text-danger" : "" }`}>{product?.delta}</td>
                        <td className="pl-3 pr-3">{product.upccode}</td>
                        <td className="pl-3 pr-3">
                          <Moment format="MM/DD/YYYY hh:mm A" withTitle>
                            {product.updated_at || "-"}
                          </Moment>
                        </td>
                        <td className="pl-3 pr-3">
                          {getStatusTextAndColor(product?.inventory_status)}
                        </td>
                        <td>
                          <i
                            className="fa fa-trash"
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={(e) =>
                              DeleteInventoryMapping(
                                `${product.id}`
                              )
                            }
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <PagePagination pagination={pagination} handlePagination={handlePagination} page={page} totalCount={totalProducts} />
              </>}
          </Card>
        </div>
      </Row>
    </Container>
    
    <Modal
      style={{ marginTop: "100px" }}
      isOpen={openDeleteModal}
      size={"lg"}
      onExit={toggle}
      toggle={() => toggle()}
    >
      <ModalHeader className="bg-primary text-white">
        <span className="text-white h3">Inventory Mapping</span>
      </ModalHeader>

      <ModalBody>
        <h3 className="mb-3">
          Are you sure you want to delete the mapping?
        </h3>
      </ModalBody>
      
      <ModalFooter className="mt-0 pt-0">
        <Button color="secondary" onClick={(e) => setOpenDeleteModal(false)}>
          Cancel
        </Button>
        <Button
          color="danger"
          id="delete_btn"
          onClick={(e) =>
            deleteInventoryMappingRecord(deleteInventoryRecordId)
          }
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  </>
);
}

export default InventoryListing;
