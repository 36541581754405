import fileDownload from 'js-file-download';
import moment from 'moment';
import React from 'react'
import { useToasts } from 'react-toast-notifications';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { api } from 'services/api';

const OrdersExport = ({ exportButton, setExportButton, exportDropdownOpen, setExportDropdownOpen, getParams, filter }) => {
    const { addToast } = useToasts();

    const handleExport = ((url, fileNamePrefix) => {
        setExportButton({ label: "Exporting...", disabled: true, });
        let new_params = getParams(filter);
        api.get(url, { params: new_params, })
            .then((response) => response.data)
            .then((response) => {
                const success = response?.success | false;
                if (success) {
                    addToast(response.message, { appearance: "success", autoDismiss: false });
                } else {
                    const timestamp = moment().format("MMMM-Do-YYYY-h:mm:ss-a");
                    const filename = fileNamePrefix + timestamp + ".csv";
                    fileDownload(response, filename);
                }
                setExportButton({ label: "Export", disabled: false });
            });
    });


    return (
        <ButtonDropdown className='mx-2 d-none d-lg-block' size={"sm"} isOpen={exportDropdownOpen} toggle={() => setExportDropdownOpen(!exportDropdownOpen)}>
            <DropdownToggle style={{ width: '100px' }} color="white" className="text-primary p-10px" caret>
                <i className="fas fa-file-csv" /> {exportButton.label}
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => { handleExport("order/export/", "orders_") }}>Orders</DropdownItem>
                <DropdownItem onClick={() => { handleExport("order/export/item", "orders_line_item_") }}>Order Line Items</DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>
    )
}

export default OrdersExport