import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Card,
    CardHeader,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    UncontrolledDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Col,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Input,
} from "reactstrap";
import { AuthContext } from "contexts/AuthContext";
import { api } from 'services/api';
import Filter from 'components/Filters/Filter';
import Moment from 'react-moment';
import BadgePillFilter from 'components/Filters/Badge';
import DateRange from "../../components/Filters/DateRange";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import fileDownload from "js-file-download";
import { FulfillmentStatus } from "../../components/FulfillmentTracking/index";
import TrackingStatusModalForm from 'components/Modal/TrackingStatus';
import { ComponentContext } from "../../contexts/ComponentContext";
import { useLocation } from "react-router-dom";
import axios from "axios";
import PagePagination from 'feature/pagination/Pagination';
import useMobileDetection from 'customHooks/useMobileDetection';
import { mobileScreenThresholdValue } from 'utils/constants';
import { Sort, handleSort } from "../../utils/commonFunctions";
import { prefixAndFilterMapping } from '../../customHooks/useFilter';
import { addURLParam, processURLSearchParams, removeAllURLParams, removeURLParam } from '../../utils/urls';
import { removeBadge } from '../../utils/commonFunctions';
import useFilter from '../../customHooks/useFilter';


const Fulfillment = () => {
    let order_id = useQuery().get("order_id");
    let search_id = useQuery().get("search");

    // Check whether or not to apply the default filters
    let applyDefaultFilters = order_id != null || search_id !=null;

    // Default filters to use. To add default filters into URL params we have handled one useeffect at below.
    const defaultFilterTrackingStatuses = [
        { value: "In Transit", label: "In Transit" },
        { value: "Order Being Packed", label: "Order Being Packed" },
        { value: "No Status Yet", label: "No Status Yet" },
        { value: "Pickup Scan", label: "Pickup Scan" },
        { value: "Shipment Issue", label: "Shipment Issue" },
        { value: "Voided Information", label: "Voided Information" }];

    const [fulfillment, setFulfillment] = useState([]);
    const [totalFulfillment, setTotalFulfillment] = useState(0);
    const { user, isAuthentication } = useContext(AuthContext);
    const [sort, setSort] = useState("-id");
    const [sortOrder, setSortOrder] = useState({order: "", columnName: ""});
    const [params, setParams] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(1);
    const [updateFulfillment, setUpdateFulfillment] = useState([]);
    const [clearall, setClearAll] = useState(false);
    const [assignee, setAssignee] = useState([]);
    const [filter, setFilter] = useFilter({ tracking_statuses: applyDefaultFilters ? [] : defaultFilterTrackingStatuses });

    const [openTrackingNumberModal, setopenTrackingNumberModal] = useState();
    const [openTrackingCompanyModal, setOpenTrackingCompanyModal] = useState();
    const [fulfillmentId, setFulfillmentId] = useState();
    const [updateTrackingCompany, setUpdateTrackingCompany] = useState();
    const [updateTrackingNumber, setUpdateTrackingNumber] = useState();
    const [updateTrackingUrl, setUpdateTrackingUrl] = useState();
    const [responseTrackingUrl, setResponseTrackingUrl] = useState(null);
    const [responseTrackingNumber, setResponseTrackingNumber] = useState(null);
    const [responseTrackingCompany, setResponseTrackingCompany] = useState(null);
    const [updateType, setUpdateType] = useState(null);
    const cancelToken = React.useRef(undefined);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    };

    const [openModal, setOpenModal] = useState({
        tracking_status: false,
    });

    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return [];
        },
    });

    // Check weather screen size is mobile or desktop
    const isMobile = useMobileDetection(mobileScreenThresholdValue);

    /**
     * Function for toggle tracking number editing modal
     */
    const toggleTrackingNumberModal = () => {
        setopenTrackingNumberModal(!openTrackingNumberModal);
        setResponseTrackingUrl(null);
        setResponseTrackingNumber(null);
        setResponseTrackingCompany(null);
        setUpdateType(null);
    };

    const { addToast } = useToasts();
    const [status, setStatus] = useState([]);

    /**
     * 
     * @returns Function for getting filtering params
     */

    const getParams = () => {
        let new_params = {
            page,
            ordering: sort,
        };

        if (filter?.fulfillmentStartDate && filter.fulfillmentEndDate) {
            new_params.fulfillment_date_start = filter.fulfillmentStartDate.format("Y-M-D");
            new_params.fulfillment_date_end = filter.fulfillmentEndDate.format("Y-M-D");
            setClearAll(true);
        }
        if (filter?.assignees?.length > 0) {
            new_params.assignee = [];
            filter.assignees.map((item) => new_params.assignee.push(item.value));
            setClearAll(true);
        }

        if (filter?.search?.length > 0) {
            new_params.search = filter.search;
            setClearAll(true);
        }

        if (filter?.brands?.length > 0) {
            new_params.brand = [];
            filter.brands.map(item => new_params.brand.push(item.value));
            setClearAll(true);
        }

        if (filter?.tracking_statuses?.length > 0) {
            new_params.shipment_status = [];
            filter.tracking_statuses.map(item => new_params.shipment_status.push(item.value));
            setClearAll(true);
        }

        if (filter?.tracking_companies?.length > 0) {
            new_params.tracking_company = [];
            filter.tracking_companies.map(item => new_params.tracking_company.push(item.value));
            setClearAll(true);
        }

        setParams(new_params);
        return new_params;
    }

    const { component } = useContext(ComponentContext);

    /**
     * useEffect for API call for listing page
     */
    useEffect(() => {
        setLoading(true);

        const new_params = getParams();
        if (typeof cancelToken.current != undefined) {
            cancelToken.current !== undefined && cancelToken.current.cancel("Operation canceled due to new request.");
        }
        cancelToken.current = axios.CancelToken.source();

        api
            .get("/fulfillment/listing", {
                params: new_params,
                cancelToken: cancelToken.current.token
            })
            .then((response) => {
                const list = response.data.results.map((fulfillment) => {
                    const s = selected.filter((item) => parseInt(item.id) === fulfillment.order_id);
                    fulfillment.checked = s.length > 0;
                    return fulfillment;
                })
                setFulfillment(response);
                setTotalFulfillment(response.data.count);
                const totalPages = Math.ceil(
                    response.data.count / window.env.PAGE_SIZE
                );
                const previous = page !== 1 ? page - 1 : 1;
                const next = page !== totalPages ? page + 1 : totalPages;
                setPagination({
                    previous,
                    next,
                    pages: () => {
                        let startCount = 1;
                        let endCount = isMobile ? 4 : 12;
                        let numberCount = Math.round(endCount / 2);
                        const pageList = [];
                        if (numberCount < 0) numberCount = 1;
                        startCount = page - numberCount;
                        if (startCount <= 0) startCount = 1
                        if (page !== 1) endCount = page + numberCount;
                        if (endCount > totalPages) endCount = totalPages;

                        if (totalPages >= endCount) {
                            for (let i = startCount; i <= endCount; i++) {
                                pageList.push(i);
                            }
                        } else if (totalPages >= 1) {
                            for (let i = 1; i <= totalPages; i++) {
                                pageList.push(i);
                            }
                        }
                        return pageList;
                    },
                });
                setLoading(false);
                delete new_params["page"];
                delete new_params["ordering"];
                setParams(new_params);
            })
            .catch((error) => console.log(error));
    }, [page, sort, refresh, filter, component]);

    /* This useEffect belongs to default fitlers for fulfillment page */
    useEffect(() => {
        if(!applyDefaultFilters) {
            defaultFilterTrackingStatuses?.map((item) => {
                addURLParam(window, prefixAndFilterMapping["tracking_statuses"], item?.value)
            })
        }
    },[])

    useEffect(() => {
        api.get('fulfillment/tracking-status/')
            .then(response => {
                setStatus(response.data.results)
            })
            .catch(error => console.log(error))

        const urlParams = new URLSearchParams(window.location.search);
        const searchParam = urlParams.get('search');
        if (searchParam) {
            filter.tracking_statuses = applyDefaultFilters
        } else {
            filter.tracking_statuses = defaultFilterTrackingStatuses
        }
    }, []);

    const [exportButton, setExportButton] = useState({
        label: "Export",
        disabled: false,
    });

    const urlSearchParams = useLocation().search;
    React.useMemo(() => {
        if (filter.urlParamsProcessed == false) processURLSearchParams(new URLSearchParams(urlSearchParams), filter, setFilter);
    }, [urlSearchParams]);

    /**
     * Function for handling Export Functionality
     */
    const handleExportFulfillments = () => {
        setExportButton({
            label: "Exporting...",
            disabled: true,
        });

        let new_params = params;
        new_params.fulfillment_order_ids = selected.map((item) => item.id);

        api
            .get("fulfillment/export/", {
                params: new_params,
            })
            .then((response) => response.data)
            .then((response) => {
                const success = response?.success | false;

                if (success) {
                    addToast(response.message, {
                        appearance: "success",
                        autoDismiss: false,
                    });
                } else {
                    const timestamp = moment().format("MMMM-Do-YYYY-h:mm:ss-a");
                    const filename = "fulfillment_" + timestamp + ".csv";
                    fileDownload(response, filename);
                }

                setExportButton({
                    label: "Export",
                    disabled: false,
                });
            });
    };

    const handleRefresh = () => {
        setRefresh(!refresh);
    };

    const handlePagination = (page) => {
        setPage(page);
    };

    const refFulfillmentCheckbox = useRef(false);
    const handleFulfillmentCheckbox = (e) => {
        refFulfillmentCheckbox.current = e.target.checked;
        let checkboxes = document.querySelectorAll(".fulfillmentCheckbox");
        let items = [];
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = e.target.checked;
            if (e.target.checked === true) {
                items.push({ id: checkboxes[i].value, title: checkboxes[i].name });
                setSelected(items);
            }
            else {
                setSelected(items);
            }
        }
    };

    const handleCheckbox = (e) => {
        let checkbox = [...new Set(selected)];

        if (e.target.checked) {
            checkbox.push({ id: e.target.value, title: e.target.name });
        } else {
            checkbox = checkbox.filter(item => item.id !== e.target.value);
        }

        setSelected(checkbox);

    };

    /**
     * Function for handling fulfillment date filter
     * @param {*} start 
     * @param {*} end 
     */
    const handleDateRange = (start, end) => {
        setFilter({
            ...filter,
            fulfillmentStartDate: start,
            fulfillmentEndDate: end,
        });
        addURLParam(window, "startDate", start);
        addURLParam(window, "endDate", end);
    };

    if (isAuthentication && !user.isSupplier && !user.isAdmin && !user.isRetailer) {
        window.location.href = window.env.APP_URL;
    }

    /**
     * Function for handling Brands filter
     * @param {*} e 
     */
    const handleBrands = (e, prefix) => {
        let checkbox = [...new Set(filter.brands)];

        if (e.target.checked) {
            checkbox.push({ value: e.target.value, label: e.target.dataset.label });
            addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value);
            removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
        }
        setFilter({ ...filter, brands: checkbox });
    };

    /**
     * Handling function for Tracking status filter
     * @param {*} e 
     */
    const handleTrackingStatus = (e, prefix) => {
        if (e == "__all__") {
            let trackingStatus = [];
            let checkbox = document.querySelectorAll(".checkbox_tracking_status");
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                trackingStatus.push({
                    value: checkbox[i].value,
                    label: checkbox[i].dataset.label,
                });
            }
            setFilter({ ...filter, tracking_statuses: trackingStatus });
        } else {
            let checkbox = [...new Set(filter.tracking_statuses)];
            if (e.target.checked) {
                checkbox.push({ value: e.target.value, label: e.target.dataset.label });
                addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
            } else {
                checkbox = checkbox.filter(item => item.value !== e.target.value);
                removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
            }
            setFilter({ ...filter, tracking_statuses: checkbox });
        }
    };

    /**
     * Function for handling tracking companies filter
     * @param {*} e 
     */
    const handleTrackingCompanies = (e, prefix) => {
        let checkbox = [...new Set(filter.tracking_companies)];

        if (e.target.checked) {
            checkbox.push({ value: e.target.value, label: e.target.dataset.label });
            addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value);
            removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
        }

        setFilter({ ...filter, tracking_companies: checkbox });
    };

    const handleUpdateFulfillment = (e) => {
        setUpdateFulfillment(e);
        setOpenModal({ updateFulfillment: !openModal.updateFulfillment });
    };

    const exitUpdateFulfillmentModal = () => {
        setOpenModal({
            updateFulfillment: false,
        });
    };

    /**
     * Function for handling Assignee filter
     * @param {*} e 
     */
    const handleAssignee = (e, prefix) => {
        if (e == "__all__") {
            let assignee = [];

            let checkbox = document.querySelectorAll(".checkbox_assignee");
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                assignee.push({
                    value: checkbox[i].value,
                    label: checkbox[i].dataset.label,
                });
                addURLParam(window, prefixAndFilterMapping[prefix], checkbox[i].value);
            }
            setFilter({ ...filter, assignees: assignee });
        } else {
            let checkbox = [...new Set(filter.assignees)];
            if (e.target.checked) {
                checkbox.push({ value: e.target.value, label: e.target.dataset.label });
                addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
            } else {
                checkbox = checkbox.filter((item) => item.value !== e.target.value);
                removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
            }
            setFilter({ ...filter, assignees: checkbox });
        }
    };

    let timer = null;
    /**
     * Function for handling search
     * @param {*} e 
     */
    const handleSearch = (e) => {
        addURLParam(window, "search", e.target.value);
        clearTimeout(timer);
        timer = setTimeout(function () {
            setFilter({
                ...filter,
                search: e.target.value
            })
        }, 750);
    };

    const clearCustomFilter = (prefix) => {
        if (prefix === "brand") {
            setFilter({
                ...filter, brands: []
            });
        } else if (prefix === "assignee") {
            setFilter({
                ...filter,
                assignees: [],
            });
        } else if (prefix === "tracking_statuses") {
            setFilter({
                ...filter, tracking_statuses: []
            });
        } else if (prefix === "tracking_companies") {
            setFilter({
                ...filter, tracking_companies: []
            });
        }
        removeURLParam(window, prefixAndFilterMapping[prefix]);
        setClearAll(false);

        let checkbox = document.querySelectorAll('.checkbox_' + prefix + ':checked');
        if (checkbox.length > 0) {
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = false;
            }
        }
    };

    const handleClearAll = () => {
        document.getElementById("fulfillmentPageSearchInput").value = "";

        setClearAll(false);
        setSelected([]);
        window.history.replaceState({}, "", `${window.location.pathname}`);
        document.getElementById("fulfillmentPageSearchInput").value = "";

        setFilter({
            fulfillmentStartDate: null,
            fulfillmentEndDate: null,
            search: null,
            brands: [],
            assignees: [],
            tracking_statuses: [],
            tracking_companies: [],
            order_id: ""
        });
        removeAllURLParams();
    };

    /**
     * Function for fetching Tracking related data
     * @param {*} fulfillment 
     * @param {*} update_type 
     */
    const getInlineItems = (fulfillment, update_type) => {
        api({
            method: 'get',
            params: { fulfillment: fulfillment, update_type: update_type },
            url: "fulfillment/update/inlineitems/",
        }).then((response) => {
            if (response?.data?.response) {
                setResponseTrackingUrl(response.data.response.tracking_url);
                setResponseTrackingNumber(response.data.response.tracking_number);
                setResponseTrackingCompany(response.data.response.tracking_company);
            }
        }).catch((error) => console.log("error", error));
    };

    /**
     * Function to handle Tracking Number onClick of button
     * @param {*} fulfillment 
     * @param {*} update_type 
     * @param {*} trackingNumber 
     * @param {*} trackingUrl 
     */
    const handleTrackingNumberModal = (fulfillment, update_type) => {
        toggleTrackingNumberModal();
        setUpdateType(update_type);
        setFulfillmentId(fulfillment);
        getInlineItems(fulfillment, update_type);
    };

    /**
     * Function for handling POST request and saving the data
     */
    const handleData = () => {
        const data = {
            fulfillment: fulfillmentId
        };

        if (updateType === "tracking_number") {
            data["tracking_number"] = updateTrackingNumber || responseTrackingNumber;
            data["tracking_url"] = updateTrackingUrl || responseTrackingUrl;
            data["tracking_company"] = updateTrackingCompany || responseTrackingCompany;
        }

        api({
            method: 'post',
            url: "fulfillment/update/inlineitems/",
            data: data
        }).then((response) => {
            addToast("Saved Successfully!", { appearance: "success", autoDismiss: true });
        }).catch((error) => {
            let message = "Something went wrong, please try again!!";
            if (error?.response?.data?.error) {
                message = error.response.data.error;
            };
            addToast(message, { appearance: "error" });
        });
    }
    const searchInput=document.getElementById("fulfillmentPageSearchInput")
    return (
        <>
            {isAuthentication ? (
                <Container fluid>
                    {/* Ref of Assignee modal form  */}
                    <TrackingStatusModalForm
                        data={status}
                        isOpen={openModal.tracking_status}
                        orders={selected}
                        onExit={exitUpdateFulfillmentModal}
                    />

                    <div className={"pt-7"}>
                        {isAuthentication && (
                            <Row className={"mb-2"}>
                                <Col xs={10} lg={"2"} className={"text-left"}>
                                    <input
                                        type="text"
                                        id="fulfillmentPageSearchInput"
                                        className={"form-control form-control-sm"}
                                        placeholder={"Order ID"}
                                        defaultValue={filter.search}
                                        onKeyDown={handleSearch}
                                        newparams={""}
                                    />
                                </Col>
                                <Col xs={2} lg={"10"} className={"text-right"}>
                                    <div className={"d-none d-lg-inline-block"}>
                                        <button
                                            className="btn btn-neutral btn-sm p-9px"
                                            onClick={handleRefresh}
                                        >
                                            <i className="fas fa-sync-alt"></i>
                                        </button>

                                        <Filter
                                            name={"Assignee"}
                                            prefix={"assignee"}
                                            onChange={handleAssignee}
                                            newparams={params}
                                            selected={filter.assignees}
                                            url={"fulfillment/filter/assignee/"}
                                            inputSearch={true}
                                            onClear={() => clearCustomFilter("assignee")}
                                        />

                                        <Filter
                                            name={"Brands"}
                                            prefix={"brand"}
                                            onChange={handleBrands}
                                            newparams={params}
                                            selected={filter.brands}
                                            url={"fulfillment/filter/brand/"}
                                            onClear={() => clearCustomFilter("brand")} />

                                        <Filter name={"Tracking Status"}
                                            prefix={"tracking_statuses"}
                                            onChange={handleTrackingStatus}
                                            newparams={params}
                                            selected={filter.tracking_statuses}
                                            url={"fulfillment/filter/tracking/status/"}
                                            onClear={() => clearCustomFilter("tracking_statuses")}
                                        />

                                        <Filter name={"Tracking Company"}
                                            prefix={"tracking_companies"}
                                            onChange={handleTrackingCompanies}
                                            newparams={params}
                                            selected={filter.tracking_companies}
                                            url={"fulfillment/filter/tracking/company/"}
                                            onClear={() => clearCustomFilter("tracking_companies")}
                                        />

                                        <DateRange onCallback={handleDateRange} button_name={"Fulfillment Date"}></DateRange>

                                        <Button
                                            color={"primary"}
                                            size={"sm"}
                                            disabled={exportButton.disabled}
                                            onClick={handleExportFulfillments}
                                            className='p-9px'
                                        >
                                            <i className="fas fa-file-csv"></i> {exportButton.label}
                                        </Button>

                                    </div>
                                </Col>
                            </Row>
                        )}

                        <Row>
                            {isAuthentication && (
                                <div className="col">
                                    <Card className="shadow mt-3">
                                        <CardHeader className="border-0">
                                            <Row>
                                                <Col style={{ display: "flex" }}>
                                                    <h3 className="mb-0">
                                                        Fulfillment <small>({totalFulfillment})</small>
                                                    </h3>
                                                    {clearall &&
                                                        <a href={void (0)}
                                                            className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                                                            onClick={handleClearAll}
                                                            id="clear_all_filters">
                                                            <i className="fas fa-times-circle"></i> Clear All filters
                                                        </a>
                                                    }
                                                    <Col className="text-right">
                                                        {(selected.length > 0 && user.isAdmin) && (
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    className="btn text-primary"
                                                                    href="#"
                                                                    role="button"
                                                                    size="sm"
                                                                    color="white"
                                                                    onClick={(e) => e.preventDefault()}
                                                                >
                                                                    <i className="fas fa-cogs" /> Actions
                                                                </DropdownToggle>
                                                                <DropdownMenu
                                                                    className="dropdown-menu-arrow"
                                                                    right
                                                                >

                                                                    <DropdownItem href="#"
                                                                        onClick={(e) => setOpenModal({
                                                                            tracking_status: !openModal.tracking_status,
                                                                        })}>
                                                                        <i className="fas fa-shipping-fast fa-fw" /> Update Tracking Status
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        )}
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <Col sm="12">
                                                <BadgePillFilter
                                                    data={filter}
                                                    selected={selected}
                                                    onClick={(e) => {removeBadge(e, filter, setFilter, selected, setSelected,setClearAll, searchInput)}}
                                                    title={'Fulfillment'}
                                                />
                                            </Col>
                                        </CardHeader>
                                        {isLoading ? (
                                            <div className="text-center mt-3 mb-3">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <Table
                                                    className="align-items-center table-flush"
                                                    responsive
                                                >
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                <input
                                                                    type="checkbox"
                                                                    name={"check_fulfillment"}
                                                                    id={"checkbox_fulfillment"}
                                                                    ref={refFulfillmentCheckbox}
                                                                    defaultChecked={refFulfillmentCheckbox.current}
                                                                    onChange={handleFulfillmentCheckbox}
                                                                />
                                                            </th>
                                                            <th scope="col" className="pl-1 pr-1">
                                                                <Sort
                                                                    onClick={() =>handleSort(sort,"order_id", setSortOrder, setSort)}
                                                                    sortingOrder={sortOrder}
                                                                    column="order_id"
                                                                >
                                                                    Order Id
                                                                </Sort>
                                                            </th>

                                                            <th scope="col" className="pl-4 pr-1">
                                                                <Sort
                                                                    onClick={() =>handleSort(sort,"order__shopify_created_at", setSortOrder, setSort)}
                                                                    sortingOrder={sortOrder}
                                                                    column="order__shopify_created_at"
                                                                >
                                                                    Order Date
                                                                </Sort>
                                                            </th>

                                                            <th scope="col" className="pl-4 pr-1">
                                                                <Sort
                                                                    onClick={() =>handleSort(sort,"tracking_id", setSortOrder, setSort)}
                                                                    sortingOrder={sortOrder}
                                                                    column="tracking_id"
                                                                >
                                                                    Fulfillment Id
                                                                </Sort>
                                                            </th>
                                                            <th scope="col" className="pl-4 pr-1">
                                                                <Sort
                                                                    onClick={() =>handleSort(sort,"tracking_created_at", setSortOrder, setSort)}
                                                                    sortingOrder={sortOrder}
                                                                    column="tracking_created_at"
                                                                >
                                                                    Fulfillment Date
                                                                </Sort>
                                                            </th>
                                                            <th scope="col" className="pl-4 pr-1">
                                                                <Sort 
                                                                    onClick={() =>handleSort(sort,"expected_delivery_date", setSortOrder, setSort)} 
                                                                    sortingOrder={sortOrder}
                                                                    column="expected_delivery_date"
                                                                >
                                                                    Expected Delivery Date
                                                                </Sort>
                                                            </th>
                                                            <th scope="col" className="pl-1 pr-1">
                                                                Assignee
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-1">
                                                                <Sort onClick={() => handleSort(sort,"tracking_number", setSortOrder, setSort)} sortingOrder={sortOrder} column="tracking_number">
                                                                    Tracking Number
                                                                </Sort>
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-1">
                                                                <Sort onClick={() => handleSort(sort,"tracking_company", setSortOrder, setSort)} sortingOrder={sortOrder} column="tracking_company">
                                                                    Tracking Company
                                                                </Sort>
                                                            </th>
                                                            <th scope="col" className="pl-1 pr-1">
                                                                <Sort onClick={() => handleSort(sort,"shipment_status", setSortOrder, setSort)} sortingOrder={sortOrder} column="shipment_status">
                                                                    Tracking Status
                                                                </Sort>
                                                            </th>
                                                            <th scope="col" className="pl-1 pr-1">
                                                                <Sort onClick={() => handleSort(sort,"tracking_updated_at", setSortOrder, setSort)} sortingOrder={sortOrder} column="tracking_updated_at">
                                                                    Tracking Updated at
                                                                </Sort>
                                                            </th>
                                                            <th scope="col" className="pl-1 pr-1"></th>
                                                            <th scope="col" className="pl-1 pr-1"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {fulfillment?.data?.results && fulfillment.data.results.map((fulfillment) => (
                                                            <tr key={fulfillment.order_id}>
                                                                <td className={"pr-3 pl-3"}>
                                                                    <input
                                                                        type="checkbox"
                                                                        name={fulfillment.order_id}
                                                                        value={fulfillment.order_id}
                                                                        id={"checkbox_" + fulfillment.order_id}
                                                                        className={"fulfillmentCheckbox"}
                                                                        onChange={(e) => handleCheckbox(e)}
                                                                        defaultChecked={fulfillment?.checked}
                                                                    />
                                                                </td>

                                                                <td className="pl-1 pr-1">
                                                                    <a href={
                                                                        window.env.APP_URL + "order/" + fulfillment.fulfillment_order_id
                                                                    }
                                                                        className="btn btn-link p-0">
                                                                        {fulfillment.order_id}
                                                                    </a>
                                                                </td>
                                                                <td className="pl-4 pr-1">
                                                                    <Moment format="MMM. DD, YYYY" withTitle>
                                                                        {fulfillment.order_date}
                                                                    </Moment>
                                                                </td>
                                                                <td className="pl-4 pr-1">
                                                                    {fulfillment.tracking_id}
                                                                </td>
                                                                <td className="pl-4 pr-1">
                                                                    <Moment format="MMM. DD, YYYY" withTitle>
                                                                        {fulfillment.tracking_created_at}
                                                                    </Moment>
                                                                </td>
                                                                <td className="pl-4 pr-1">
                                                                    {fulfillment.expected_delivery_date ?
                                                                        <Moment format="MMM. DD, YYYY" withTitle>
                                                                            {fulfillment.expected_delivery_date}
                                                                        </Moment>
                                                                        : "-"
                                                                    }
                                                                </td>
                                                                <td scope="col" className="pl-1 pr-1">
                                                                    {fulfillment.assignee}
                                                                </td>
                                                                <td scope="col" className="pl-3 pr-1" title={fulfillment.tracking_number}>
                                                                    {fulfillment.tracking_number ? 
                                                                        <a className='btn btn-link p-0' href={fulfillment.tracking_url} target='_blank'>
                                                                            {fulfillment.tracking_number}
                                                                        </a> : 
                                                                        <span className='mr-4'>-</span>
                                                                    }
                                                                    <button id={"TrackingNumberButton" + fulfillment.tracking_number}
                                                                        onClick={(e) => handleTrackingNumberModal(fulfillment.id, "tracking_number")}
                                                                        className="btn btn-link p-0 trackingNumberButton"
                                                                    >
                                                                        <i className="fas fa-edit text-center" style={{ cursor: "pointer" }}></i>
                                                                    </button>
                                                                </td>
                                                                <td className="pl-3 pr-1">
                                                                    {fulfillment.tracking_company ? fulfillment.tracking_company : "-"}
                                                                </td>

                                                                <td scope="col" className="pl-1 pr-1">
                                                                    <FulfillmentStatus data={status} fulfillment={fulfillment.order_id}>
                                                                        {fulfillment.shipment_status}
                                                                    </FulfillmentStatus>
                                                                </td>

                                                                <td scope="col" className="pl-1 pr-1">
                                                                    <Moment format="MM/DD/YYYY HH:mm A z">
                                                                        {fulfillment.tracking_updated_at}
                                                                    </Moment>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                <Modal isOpen={openTrackingNumberModal} size={"sm"}
                                                >
                                                    <ModalHeader className="bg-primary text-white">
                                                        <span className="text-white h3">Edit Fulfillment</span>
                                                    </ModalHeader>
                                                    <ModalBody>
                                                        <h3 className="mb-3">
                                                            Tracking Number
                                                        </h3>
                                                        <Input defaultValue={responseTrackingNumber} onChange={(e) => setUpdateTrackingNumber(e.target.value)} />
                                                        <h3>
                                                            Tracking Url
                                                        </h3>
                                                        <Input defaultValue={responseTrackingUrl} onChange={(e) => setUpdateTrackingUrl(e.target.value)} />
                                                        <h3>
                                                            Tracking Company
                                                        </h3>
                                                        <Input defaultValue={responseTrackingCompany} onChange={(e) => setUpdateTrackingCompany(e.target.value)} />

                                                        <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
                                                            <button className='btn btn-primary btn-sm mr-1' style={{ flex: 1, marginLeft: 175 }} onClick={handleData}>
                                                                <i className='fas fa-save fa-fw' />
                                                            </button>
                                                            <button className="btn btn-danger btn-sm mr-1" style={{ flex: 1 }} onClick={toggleTrackingNumberModal}>
                                                                <i class="fas fa-times fa-fw"></i>
                                                            </button>
                                                        </div>
                                                    </ModalBody>
                                                </Modal>
                                                <PagePagination pagination={pagination} handlePagination={handlePagination} page={page} totalCount={totalFulfillment} />
                                            </>
                                        )}
                                    </Card>
                                </div>
                            )}
                        </Row>
                    </div>
                </Container>
            ) : null}
        </>
    )
}

export default Fulfillment;
