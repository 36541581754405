/*
    Author - Akshay Vishwakarma
    Description - This componenet is for option-1 form 
    to create new record
    - When user checks  "By Shipping Info", Then this form gets
    displayed
    Jira (if any) - BCD - 4091
*/

import {
    Col,
    Label,
    Row,
    Input,
} from "reactstrap";

import {
    CustomMultiSelectDropdown,
} from "../CustomDropdown/CustomDropdown";
import ParentChildProductComponent from "./ParentChildProductComponent";

const ByShippingInfo = ({ url, selectedProduct, setSelectedProduct, selectedChildProduct, setSelectedChildProduct, setSelectedState, selectedState, stateIsValid, selectedCountry, setSelectedCountry, countryIsValid, availabeQty, setAvailableQty }) => {
    //List of state hardcoded
    const stateListOptions = [
        // { value: "__all__", label: "All States" },
        { value: "AL", label: "Alabama" },
        { value: "AK", label: "Alaska" },
        { value: "AZ", label: "Arizona" },
        { value: "AR", label: "Arkansas" },
        { value: "CA", label: "California" },
        { value: "CO", label: "Colorado" },
        { value: "CT", label: "Connecticut" },
        { value: "DE", label: "Delaware" },
        { value: "DC", label: "District Of Columbia" },
        { value: "FL", label: "Florida" },
        { value: "GA", label: "Georgia" },
        { value: "HI", label: "Hawaii" },
        { value: "ID", label: "Idaho" },
        { value: "IL", label: "Illinois" },
        { value: "IN", label: "Indiana" },
        { value: "IA", label: "Iowa" },
        { value: "KS", label: "Kansas" },
        { value: "KY", label: "Kentucky" },
        { value: "LA", label: "Louisiana" },
        { value: "ME", label: "Maine" },
        { value: "MD", label: "Maryland" },
        { value: "MA", label: "Massachusetts" },
        { value: "MI", label: "Michigan" },
        { value: "MN", label: "Minnesota" },
        { value: "MS", label: "Mississippi" },
        { value: "MO", label: "Missouri" },
        { value: "MT", label: "Montana" },
        { value: "NE", label: "Nebraska" },
        { value: "NV", label: "Nevada" },
        { value: "NH", label: "New Hampshire" },
        { value: "NJ", label: "New Jersey" },
        { value: "NM", label: "New Mexico" },
        { value: "NY", label: "New York" },
        { value: "NC", label: "North Carolina" },
        { value: "ND", label: "North Dakota" },
        { value: "OH", label: "Ohio" },
        { value: "OK", label: "Oklahoma" },
        { value: "OR", label: "Oregon" },
        { value: "PA", label: "Pennsylvania" },
        { value: "RI", label: "Rhode Island" },
        { value: "SC", label: "South Carolina" },
        { value: "SD", label: "South Dakota" },
        { value: "TN", label: "Tennessee" },
        { value: "TX", label: "Texas" },
        { value: "UT", label: "Utah" },
        { value: "VT", label: "Vermont" },
        { value: "VA", label: "Virginia" },
        { value: "WA", label: "Washington" },
        { value: "WV", label: "West Virginia" },
        { value: "WI", label: "Wisconsin" },
        { value: "WY", label: "Wyoming" },
    ];

    //list of countries hardcoded
    const countryListOptions = [
        {
            "value": "AF",
            "label": "AFGHANISTAN"
        },
        {
            "value": "AL",
            "label": "ALBANIA"
        },
        {
            "value": "DZ",
            "label": "ALGERIA"
        },
        {
            "value": "AS",
            "label": "AMERICAN SAMOA"
        },
        {
            "value": "AD",
            "label": "ANDORRA"
        },
        {
            "value": "AO",
            "label": "ANGOLA"
        },
        {
            "value": "AI",
            "label": "ANGUILLA"
        },
        {
            "value": "AQ",
            "label": "ANTARCTICA"
        },
        {
            "value": "AG",
            "label": "ANTIGUA AND BARBUDA"
        },
        {
            "value": "AR",
            "label": "ARGENTINA"
        },
        {
            "value": "AM",
            "label": "ARMENIA"
        },
        {
            "value": "AW",
            "label": "ARUBA"
        },
        {
            "value": "AU",
            "label": "AUSTRALIA"
        },
        {
            "value": "AT",
            "label": "AUSTRIA"
        },
        {
            "value": "AZ",
            "label": "AZERBAIJAN"
        },
        {
            "value": "BS",
            "label": "BAHAMAS"
        },
        {
            "value": "BH",
            "label": "BAHRAIN"
        },
        {
            "value": "BD",
            "label": "BANGLADESH"
        },
        {
            "value": "BB",
            "label": "BARBADOS"
        },
        {
            "value": "BY",
            "label": "BELARUS"
        },
        {
            "value": "BE",
            "label": "BELGIUM"
        },
        {
            "value": "BZ",
            "label": "BELIZE"
        },
        {
            "value": "BJ",
            "label": "BENIN"
        },
        {
            "value": "BM",
            "label": "BERMUDA"
        },
        {
            "value": "BT",
            "label": "BHUTAN"
        },
        {
            "value": "BO",
            "label": "BOLIVIA"
        },
        {
            "value": "BA",
            "label": "BOSNIA AND HERZEGOVINA"
        },
        {
            "value": "BW",
            "label": "BOTSWANA"
        },
        {
            "value": "BV",
            "label": "BOUVET ISLAND"
        },
        {
            "value": "BR",
            "label": "BRAZIL"
        },
        {
            "value": "IO",
            "label": "BRITISH INDIAN OCEAN TERRITORY"
        },
        {
            "value": "BN",
            "label": "BRUNEI DARUSSALAM"
        },
        {
            "value": "BG",
            "label": "BULGARIA"
        },
        {
            "value": "BF",
            "label": "BURKINA FASO"
        },
        {
            "value": "BI",
            "label": "BURUNDI"
        },
        {
            "value": "KH",
            "label": "CAMBODIA"
        },
        {
            "value": "CM",
            "label": "CAMEROON"
        },
        {
            "value": "CA",
            "label": "CANADA"
        },
        {
            "value": "CV",
            "label": "CAPE VERDE"
        },
        {
            "value": "KY",
            "label": "CAYMAN ISLANDS"
        },
        {
            "value": "CF",
            "label": "CENTRAL AFRICAN REPUBLIC"
        },
        {
            "value": "TD",
            "label": "CHAD"
        },
        {
            "value": "CL",
            "label": "CHILE"
        },
        {
            "value": "CN",
            "label": "CHINA"
        },
        {
            "value": "CX",
            "label": "CHRISTMAS ISLAND"
        },
        {
            "value": "CC",
            "label": "COCOS (KEELING) ISLANDS"
        },
        {
            "value": "CO",
            "label": "COLOMBIA"
        },
        {
            "value": "KM",
            "label": "COMOROS"
        },
        {
            "value": "CG",
            "label": "CONGO"
        },
        {
            "value": "CD",
            "label": "CONGO, THE DEMOCRATIC REPUBLIC OF THE"
        },
        {
            "value": "CK",
            "label": "COOK ISLANDS"
        },
        {
            "value": "CR",
            "label": "COSTA RICA"
        },
        {
            "value": "CI",
            "label": "COTE D'IVOIRE"
        },
        {
            "value": "HR",
            "label": "CROATIA"
        },
        {
            "value": "CU",
            "label": "CUBA"
        },
        {
            "value": "CY",
            "label": "CYPRUS"
        },
        {
            "value": "CZ",
            "label": "CZECH REPUBLIC"
        },
        {
            "value": "DK",
            "label": "DENMARK"
        },
        {
            "value": "DJ",
            "label": "DJIBOUTI"
        },
        {
            "value": "DM",
            "label": "DOMINICA"
        },
        {
            "value": "DO",
            "label": "DOMINICAN REPUBLIC"
        },
        {
            "value": "EC",
            "label": "ECUADOR"
        },
        {
            "value": "EG",
            "label": "EGYPT"
        },
        {
            "value": "SV",
            "label": "EL SALVADOR"
        },
        {
            "value": "GQ",
            "label": "EQUATORIAL GUINEA"
        },
        {
            "value": "ER",
            "label": "ERITREA"
        },
        {
            "value": "EE",
            "label": "ESTONIA"
        },
        {
            "value": "ET",
            "label": "ETHIOPIA"
        },
        {
            "value": "FK",
            "label": "FALKLAND ISLANDS (MALVINAS)"
        },
        {
            "value": "FO",
            "label": "FAROE ISLANDS"
        },
        {
            "value": "FJ",
            "label": "FIJI"
        },
        {
            "value": "FI",
            "label": "FINLAND"
        },
        {
            "value": "FR",
            "label": "FRANCE"
        },
        {
            "value": "GF",
            "label": "FRENCH GUIANA"
        },
        {
            "value": "PF",
            "label": "FRENCH POLYNESIA"
        },
        {
            "value": "TF",
            "label": "FRENCH SOUTHERN TERRITORIES"
        },
        {
            "value": "GA",
            "label": "GABON"
        },
        {
            "value": "GM",
            "label": "GAMBIA"
        },
        {
            "value": "GE",
            "label": "GEORGIA"
        },
        {
            "value": "DE",
            "label": "GERMANY"
        },
        {
            "value": "GH",
            "label": "GHANA"
        },
        {
            "value": "GI",
            "label": "GIBRALTAR"
        },
        {
            "value": "GR",
            "label": "GREECE"
        },
        {
            "value": "GL",
            "label": "GREENLAND"
        },
        {
            "value": "GD",
            "label": "GRENADA"
        },
        {
            "value": "GP",
            "label": "GUADELOUPE"
        },
        {
            "value": "GU",
            "label": "GUAM"
        },
        {
            "value": "GT",
            "label": "GUATEMALA"
        },
        {
            "value": "GN",
            "label": "GUINEA"
        },
        {
            "value": "GW",
            "label": "GUINEA-BISSAU"
        },
        {
            "value": "GY",
            "label": "GUYANA"
        },
        {
            "value": "HT",
            "label": "HAITI"
        },
        {
            "value": "HM",
            "label": "HEARD ISLAND AND MCDONALD ISLANDS"
        },
        {
            "value": "VA",
            "label": "HOLY SEE (VATICAN CITY STATE)"
        },
        {
            "value": "HN",
            "label": "HONDURAS"
        },
        {
            "value": "HK",
            "label": "HONG KONG"
        },
        {
            "value": "HU",
            "label": "HUNGARY"
        },
        {
            "value": "IS",
            "label": "ICELAND"
        },
        {
            "value": "IN",
            "label": "INDIA"
        },
        {
            "value": "ID",
            "label": "INDONESIA"
        },
        {
            "value": "IR",
            "label": "IRAN, ISLAMIC REPUBLIC OF"
        },
        {
            "value": "IQ",
            "label": "IRAQ"
        },
        {
            "value": "IE",
            "label": "IRELAND"
        },
        {
            "value": "IL",
            "label": "ISRAEL"
        },
        {
            "value": "IT",
            "label": "ITALY"
        },
        {
            "value": "JM",
            "label": "JAMAICA"
        },
        {
            "value": "JP",
            "label": "JAPAN"
        },
        {
            "value": "JO",
            "label": "JORDAN"
        },
        {
            "value": "KZ",
            "label": "KAZAKHSTAN"
        },
        {
            "value": "KE",
            "label": "KENYA"
        },
        {
            "value": "KI",
            "label": "KIRIBATI"
        },
        {
            "value": "KP",
            "label": "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF"
        },
        {
            "value": "KR",
            "label": "KOREA, REPUBLIC OF"
        },
        {
            "value": "KW",
            "label": "KUWAIT"
        },
        {
            "value": "KG",
            "label": "KYRGYZSTAN"
        },
        {
            "value": "LA",
            "label": "LAO PEOPLE'S DEMOCRATIC REPUBLIC"
        },
        {
            "value": "LV",
            "label": "LATVIA"
        },
        {
            "value": "LB",
            "label": "LEBANON"
        },
        {
            "value": "LS",
            "label": "LESOTHO"
        },
        {
            "value": "LR",
            "label": "LIBERIA"
        },
        {
            "value": "LY",
            "label": "LIBYAN ARAB JAMAHIRIYA"
        },
        {
            "value": "LI",
            "label": "LIECHTENSTEIN"
        },
        {
            "value": "LT",
            "label": "LITHUANIA"
        },
        {
            "value": "LU",
            "label": "LUXEMBOURG"
        },
        {
            "value": "MO",
            "label": "MACAO"
        },
        {
            "value": "MK",
            "label": "MACEDONIA"
        },
        {
            "value": "MG",
            "label": "MADAGASCAR"
        },
        {
            "value": "MW",
            "label": "MALAWI"
        },
        {
            "value": "MY",
            "label": "MALAYSIA"
        },
        {
            "value": "MV",
            "label": "MALDIVES"
        },
        {
            "value": "ML",
            "label": "MALI"
        },
        {
            "value": "MT",
            "label": "MALTA"
        },
        {
            "value": "MH",
            "label": "MARSHALL ISLANDS"
        },
        {
            "value": "MQ",
            "label": "MARTINIQUE"
        },
        {
            "value": "MR",
            "label": "MAURITANIA"
        },
        {
            "value": "MU",
            "label": "MAURITIUS"
        },
        {
            "value": "YT",
            "label": "MAYOTTE"
        },
        {
            "value": "MX",
            "label": "MEXICO"
        },
        {
            "value": "FM",
            "label": "MICRONESIA, FEDERATED STATES OF"
        },
        {
            "value": "MD",
            "label": "MOLDOVA, REPUBLIC OF"
        },
        {
            "value": "MC",
            "label": "MONACO"
        },
        {
            "value": "MN",
            "label": "MONGOLIA"
        },
        {
            "value": "MS",
            "label": "MONTSERRAT"
        },
        {
            "value": "MA",
            "label": "MOROCCO"
        },
        {
            "value": "MZ",
            "label": "MOZAMBIQUE"
        },
        {
            "value": "MM",
            "label": "MYANMAR"
        },
        {
            "value": "NA",
            "label": "NAMIBIA"
        },
        {
            "value": "NR",
            "label": "NAURU"
        },
        {
            "value": "NP",
            "label": "NEPAL"
        },
        {
            "value": "NL",
            "label": "NETHERLANDS"
        },
        {
            "value": "AN",
            "label": "NETHERLANDS ANTILLES"
        },
        {
            "value": "NC",
            "label": "NEW CALEDONIA"
        },
        {
            "value": "NZ",
            "label": "NEW ZEALAND"
        },
        {
            "value": "NI",
            "label": "NICARAGUA"
        },
        {
            "value": "NE",
            "label": "NIGER"
        },
        {
            "value": "NG",
            "label": "NIGERIA"
        },
        {
            "value": "NU",
            "label": "NIUE"
        },
        {
            "value": "NF",
            "label": "NORFOLK ISLAND"
        },
        {
            "value": "MP",
            "label": "NORTHERN MARIANA ISLANDS"
        },
        {
            "value": "NO",
            "label": "NORWAY"
        },
        {
            "value": "OM",
            "label": "OMAN"
        },
        {
            "value": "PK",
            "label": "PAKISTAN"
        },
        {
            "value": "PW",
            "label": "PALAU"
        },
        {
            "value": "PS",
            "label": "PALESTINIAN TERRITORY, OCCUPIED"
        },
        {
            "value": "PA",
            "label": "PANAMA"
        },
        {
            "value": "PG",
            "label": "PAPUA NEW GUINEA"
        },
        {
            "value": "PY",
            "label": "PARAGUAY"
        },
        {
            "value": "PE",
            "label": "PERU"
        },
        {
            "value": "PH",
            "label": "PHILIPPINES"
        },
        {
            "value": "PN",
            "label": "PITCAIRN"
        },
        {
            "value": "PL",
            "label": "POLAND"
        },
        {
            "value": "PT",
            "label": "PORTUGAL"
        },
        {
            "value": "PR",
            "label": "PUERTO RICO"
        },
        {
            "value": "QA",
            "label": "QATAR"
        },
        {
            "value": "RE",
            "label": "REUNION"
        },
        {
            "value": "RO",
            "label": "ROMANIA"
        },
        {
            "value": "RU",
            "label": "RUSSIA"
        },
        {
            "value": "RW",
            "label": "RWANDA"
        },
        {
            "value": "SH",
            "label": "SAINT HELENA"
        },
        {
            "value": "KN",
            "label": "SAINT KITTS AND NEVIS"
        },
        {
            "value": "LC",
            "label": "SAINT LUCIA"
        },
        {
            "value": "PM",
            "label": "SAINT PIERRE AND MIQUELON"
        },
        {
            "value": "VC",
            "label": "SAINT VINCENT AND THE GRENADINES"
        },
        {
            "value": "WS",
            "label": "SAMOA"
        },
        {
            "value": "SM",
            "label": "SAN MARINO"
        },
        {
            "value": "ST",
            "label": "SAO TOME AND PRINCIPE"
        },
        {
            "value": "SA",
            "label": "SAUDI ARABIA"
        },
        {
            "value": "SN",
            "label": "SENEGAL"
        },
        {
            "value": "RS",
            "label": "SERBIA"
        },
        {
            "value": "SC",
            "label": "SEYCHELLES"
        },
        {
            "value": "SL",
            "label": "SIERRA LEONE"
        },
        {
            "value": "SG",
            "label": "SINGAPORE"
        },
        {
            "value": "SK",
            "label": "SLOVAKIA"
        },
        {
            "value": "SI",
            "label": "SLOVENIA"
        },
        {
            "value": "SB",
            "label": "SOLOMON ISLANDS"
        },
        {
            "value": "SO",
            "label": "SOMALIA"
        },
        {
            "value": "ZA",
            "label": "SOUTH AFRICA"
        },
        {
            "value": "GS",
            "label": "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS"
        },
        {
            "value": "ES",
            "label": "SPAIN"
        },
        {
            "value": "LK",
            "label": "SRI LANKA"
        },
        {
            "value": "SD",
            "label": "SUDAN"
        },
        {
            "value": "SR",
            "label": "SURINAME"
        },
        {
            "value": "SJ",
            "label": "SVALBARD AND JAN MAYEN"
        },
        {
            "value": "SZ",
            "label": "SWAZILAND"
        },
        {
            "value": "SE",
            "label": "SWEDEN"
        },
        {
            "value": "CH",
            "label": "SWITZERLAND"
        },
        {
            "value": "SY",
            "label": "SYRIAN ARAB REPUBLIC"
        },
        {
            "value": "TW",
            "label": "TAIWAN"
        },
        {
            "value": "TJ",
            "label": "TAJIKISTAN"
        },
        {
            "value": "TZ",
            "label": "TANZANIA, UNITED REPUBLIC OF"
        },
        {
            "value": "TH",
            "label": "THAILAND"
        },
        {
            "value": "TL",
            "label": "TIMOR-LESTE"
        },
        {
            "value": "TG",
            "label": "TOGO"
        },
        {
            "value": "TK",
            "label": "TOKELAU"
        },
        {
            "value": "TO",
            "label": "TONGA"
        },
        {
            "value": "TT",
            "label": "TRINIDAD AND TOBAGO"
        },
        {
            "value": "TN",
            "label": "TUNISIA"
        },
        {
            "value": "TR",
            "label": "TURKEY"
        },
        {
            "value": "TM",
            "label": "TURKMENISTAN"
        },
        {
            "value": "TC",
            "label": "TURKS AND CAICOS ISLANDS"
        },
        {
            "value": "TV",
            "label": "TUVALU"
        },
        {
            "value": "UG",
            "label": "UGANDA"
        },
        {
            "value": "UA",
            "label": "UKRAINE"
        },
        {
            "value": "AE",
            "label": "UNITED ARAB EMIRATES"
        },
        {
            "value": "GB",
            "label": "UNITED KINGDOM"
        },
        {
            "value": "US",
            "label": "UNITED STATES"
        },
        {
            "value": "UM",
            "label": "UNITED STATES MINOR OUTLYING ISLANDS"
        },
        {
            "value": "UY",
            "label": "URUGUAY"
        },
        {
            "value": "UZ",
            "label": "UZBEKISTAN"
        },
        {
            "value": "VU",
            "label": "VANUATU"
        },
        {
            "value": "VE",
            "label": "VENEZUELA"
        },
        {
            "value": "VN",
            "label": "VIETNAM"
        },
        {
            "value": "VG",
            "label": "VIRGIN ISLANDS, BRITISH"
        },
        {
            "value": "VI",
            "label": "VIRGIN ISLANDS, U.S."
        },
        {
            "value": "WF",
            "label": "WALLIS AND FUTUNA"
        },
        {
            "value": "EH",
            "label": "WESTERN SAHARA"
        },
        {
            "value": "YE",
            "label": "YEMEN"
        },
        {
            "value": "ZM",
            "label": "ZAMBIA"
        },
        {
            "value": "ZW",
            "label": "ZIMBABWE"
        },
        {
            "value": "ME",
            "label": "MONTENEGRO"
        },
        {
            "value": "IM",
            "label": "ISLE OF MAN"
        },
        {
            "value": "JE",
            "label": "JERSEY"
        }
    ]

    const onQtyChange = (event) => {
        let value = event.target.value;
        setAvailableQty(value)
    };

    return (
        <div>
            <Row className="mb-2">
                <Col lg={6} xs={12} className="mt-sm-2">
                    <Label>Shipping State *</Label>
                    <CustomMultiSelectDropdown
                        onChangeHandler={setSelectedState}
                        options={stateListOptions}
                        placeholder="Select one or multiple state(s)"
                        isValid={stateIsValid}
                        values={selectedState}
                        isDisabled={selectedCountry.length > 0 ? true : false}
                    />
                </Col>
                <Col lg={6} xs={12} className="mt-sm-2">
                    <Label>Shipping Countries *</Label>
                    <CustomMultiSelectDropdown
                        onChangeHandler={setSelectedCountry}
                        options={countryListOptions}
                        placeholder="Select one or multiple countries"
                        isValid={countryIsValid}
                        values={selectedCountry}
                        isDisabled={selectedState.length > 0 ? true : false}
                    />
                </Col>
            </Row>
            <span>* Note : Select either shipping state or shipping country</span>
            <Row className="mt-4">
                <Col lg={12} xs={12} className="mt-sm-2">
                    <Label>Available Qty</Label>
                    <Input
                        id="availableQty"
                        name="availableQty"
                        placeholder="Enter the available quantity"
                        type="text"
                        inputMode="numeric"
                        value={availabeQty}
                        onChange={onQtyChange}
                    />
                </Col>
            </Row>
            <Row>
                <ParentChildProductComponent
                    url={url}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    selectedChildProduct={selectedChildProduct}
                    setSelectedChildProduct={setSelectedChildProduct}

                />
            </Row>
        </div>
    )
}

export default ByShippingInfo