import React, { useEffect, useRef, useState } from 'react';
import { Button, DropdownMenu, DropdownToggle, Label, UncontrolledDropdown } from "reactstrap";
import { api } from "../../../services/api";

function Filter({ prefix, name, onChange, selected, url, inputSearch = true, color = 'white', onClear, disablePagination = false, className='', showSelectAll = true }) {
    const [isLoading, setLoading] = useState(false)
    const [isOpen, setOpen] = useState(false);
    const [checkboxChecked, setCheckboxChecked] = useState([])
    const [data, setData] = useState({
        results: [],
        count: 0,
        next: null,
        previous: null
    });

    const [filterOptions, setFilterOptions] = useState([])
    const [paramsFilter, setParamsFilter] = useState({
        disable_pagination: disablePagination
    });

    useEffect(() => {
      if (isOpen) {
        api.get(url, {
            params: paramsFilter
        })
            .then(response => {
                let modifiedData = checkWhetherRetrievedDataIncludesSelectedOptions(response.data)
                setData(modifiedData);
                setFilterOptions(modifiedData.results);
            })
            .catch(error => console.log(error))
      }
    }, [paramsFilter, isOpen])

    let timer = null;

    const handleSearch = (e) => {
        // limpamos o timer
        clearTimeout(timer);
        // armazenamos o timer novamente
        timer = setTimeout(function () {


            setParamsFilter({
                ...paramsFilter,
                search: e.target.value
            })

        }, 150);
    }

    const handleNext = () => {

        if (data.next) {
            let results = data.results
            setLoading(true)
            api.get(data.next)
                .then(response => {
                    const new_results = results.concat(response.data.results)
                    let temporaryData = {
                        ...data,
                        results: new_results,
                        next: response.data.next,
                        previous: response.data.previous
                    }
                    setData(temporaryData)
                    setLoading(false)
                    let newOptions = checkWhetherRetrievedDataIncludesSelectedOptions(temporaryData).results;
                    setFilterOptions(newOptions);
                }
                )
                .catch(error => console.log(error))
        }
    }

    /**
     * Clear filter handler
     */
    const handleClear = () => {
        // Clear text from search box
        setParamsFilter({
            ...paramsFilter,
            search: ""
        });
        // Close dropdown
        setOpen(false);
        // Callback parent to handle clear
        onClear();
    };

    const checkWhetherRetrievedDataIncludesSelectedOptions = (options) => {
        let result = options.results.map(item => {
            let test = selected?.filter(s => (typeof item.id === "number")
                ? (parseInt(item.id) === parseInt(s.value))
                : (item.id === s.value)) // just states filter
            item.checked = test?.length > 0;
            return item;
        });
        options.results = result;
        return options
    }

    useEffect(() => {
        let modifiedData = checkWhetherRetrievedDataIncludesSelectedOptions(data);
        setData(modifiedData);
        setFilterOptions(modifiedData.results)
    }, [selected])


    return (
        <UncontrolledDropdown
            className={className}
            size={"sm"}
            isOpen={isOpen}
            toggle={() => setOpen(!isOpen)}
        >
            <DropdownToggle
                color={color}
                className={(color === 'white') ? "text-primary p-10px" : "p-10px"}
                caret
                style={{ fontSize: "0.65rem" }}
            >
                {name}
            </DropdownToggle>
            <DropdownMenu className="more-filters-dropdown-menu" right>
                <ul className={"p-2 overflow-auto"} style={{ maxHeight: "300px" }} id={"filter_" + prefix}>
                    {inputSearch && <li className={"mb-1 list-unstyled"}>
                        <input type="text"
                            placeholder={"Search " + name}
                            className={"form-control form-control-sm"}
                            onKeyDown={handleSearch}
                            defaultValue={paramsFilter.search || ""}
                        />
                    </li>}
                    {(selected && selected.length > 0) &&
                        <li className={'mb-3 '}>
                            <Button color={"primary"} size={'sm'} block={true} onClick={handleClear}>
                                <i className="fas fa-times-circle"></i> Clear
                            </Button>
                        </li>}
                    {(prefix == 'assignee' || prefix == 'distribution_states' || prefix == 'state' || prefix == 'status' || prefix == 'payment_status' || prefix == 'inventory_location'
                    || prefix == 'fulfillment' || prefix == 'sales_channel' || prefix == 'tracking_status' || prefix == 'tracking_companies' || prefix == 'payout_status'
                    || prefix == 'order_states' || prefix == 'billingStatus' || prefix == 'typeDiscount' || prefix == 'customer_states' || prefix == 'user' || prefix == 'brand' || prefix == 'discountCodeType' || prefix == 'invoiceNumbers' || prefix == "discountCodeBrand" || prefix === "userRoles") && showSelectAll &&
                    <li className={'mb-3 '}>
                        <Button disabled={selected.length === data.results.length} color={"primary"} size={'sm'} block={true} onClick={(e) => onChange('__all__', prefix)}  >
                            Select All
                        </Button>
                    </li>}
                    {prefix == 'assignee' && <li className={"list-unstyled "} key={10}>
                        <Label className={"text-sm"}>
                            <input type="checkbox"
                                id={prefix + "_0"}
                                className={"checkbox_" + prefix}
                                value={0}
                                onChange={(e) => onChange(e, prefix)}
                                data-label={'None'}
                                defaultChecked={0}
                            /> None
                        </Label>
                    </li>}

                    {filterOptions.length
                      ? filterOptions.map((option, index) =>
                        <li className={"list-unstyled "} key={index}>
                            <Label className={"text-sm"}>
                                <input
                                    type="checkbox"
                                    id={prefix + "_" + option.id}
                                    className={"checkbox_" + prefix}
                                    value={option.id}
                                    onChange={(e) => onChange(e, prefix)}
                                    data-label={option.name}
                                    checked={option.checked}
                                /> {option.name}
                            </Label>
                        </li>
                      )
                      : (
                        <div className="text-center">
                          <div className="spinner-border spinner-border-sm text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )
                    }


                  {(data.results.length < data.count) && <li className={"text-center"}>
                    {!isLoading ?
                      <Button className={"btn btn-sm "} onClick={handleNext}>show more</Button>
                            : <span className={"text-sm text-muted"}>Loading...</span>
                        }
                    </li>}

                </ul>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

export default Filter;
