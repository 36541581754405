import { CustomDropdown } from 'components/CustomDropdown/CustomDropdown';
import Toggle from 'components/Toggle';
import useInventoryLocationHook from 'hooks/useInventoryLocationHook';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Form, FormGroup, Label, Modal, ModalBody, ModalHeader, Button, Row, Col, ButtonGroup } from 'reactstrap';
import { api } from 'services/api';
import CInput from 'components/Input';
import CustomCreatableSelect from '../../../components/Select/CustomCreatableSelect';
import { schema, navigateToDifferentSectionIfNoErrorsOnCurrentSection } from './validations';
import CLabel from 'components/Label';
import CButton from 'components/Button';

// Two modes : add & update
const InventoryLocationDetailModal = ({ data, mode, inventoryLocationTypes, showInventoryLocationModel, setshowInventoryLocationModel, refetchInventoryLocation, states }) => {
    const { addToast } = useToasts();
    // React usestate reference
    const [activeSection, setactiveSection] = useState("general");
    const [inventoryLocationErrors, setInventoryLocationErrors] = useState();
    const [inventoryLocation, setInventoryLocation] = useInventoryLocationHook(data);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    // Functions
    const customDropdownOnChange = (value, name) => setInventoryLocation({ ...inventoryLocation, [name]: parseInt(value) });
    const toggle = () => { setshowInventoryLocationModel({ ...showInventoryLocationModel, show: !showInventoryLocationModel.show }); };
    const formInputFieldOnChangeHandler = (e) => setInventoryLocation({ ...inventoryLocation, [e.target.name]: e.target.value });

    const updateInventoryLocation = async () => {
        try {
            setIsButtonLoading(true);
            await api.put("settings/inventory-location-list/", inventoryLocation, { params: { inventory_location_id: data.id } })
                .then((response) => response)
                .then((response) => {
                    if (response.status === 200) {
                        addToast(response.data.message, { appearance: "success", autoDismiss: true });
                        refetchInventoryLocation();
                        setshowInventoryLocationModel({ ...showInventoryLocationModel, show: false });
                    } else {
                        let message = "Something went wrong!";
                        if (response?.message) { message = response.data.message; };
                        addToast(message, { appearance: "error", autoDismiss: true });
                    }
                    setIsButtonLoading(false);
                })
        } catch (error) {
            setIsButtonLoading(false);
            addToast(error.message, { appearance: 'error' });
        }
    }

    const addInventoryLocation = async () => {
        try {
            setIsButtonLoading(true);
            await api.post("settings/inventory-location-list/", inventoryLocation)
                .then((response) => response)
                .then((response) => {
                    if (response.status === 200) {
                        addToast(response.data.message, { appearance: "success", autoDismiss: true });
                        refetchInventoryLocation();
                        setshowInventoryLocationModel({ ...showInventoryLocationModel, show: false });
                    } else {
                        let message = "Something went wrong!";
                        if (response?.message) { message = response.data.message; };
                        addToast(message, { appearance: "error", autoDismiss: true });
                    }
                    setIsButtonLoading(false);
                })
        } catch (error) {
            setIsButtonLoading(false);
            addToast(error.message, { appearance: 'error' });
        }
    }

    /**
    * Common method for adding and updating inventory location
    * @param {e} onchange event 
    */
    const inventoryLocationFormSubmit = () => {
        console.log(schema.validate(inventoryLocation, { abortEarly: false }))
        if (!inventoryLocation.is_wms_location) {
            const result = schema.validate(inventoryLocation, { abortEarly: false });
            if (!result?.error) {
                setInventoryLocationErrors({});
                if (mode === "add") addInventoryLocation();
                if (mode === "update") updateInventoryLocation();
            } else {
                const errorData = {};
                for (let item of result.error.details) {
                    let name = item.path[0];
                    let message = item.message;
                    errorData[name] = message;
                }
                navigateToDifferentSectionIfNoErrorsOnCurrentSection(result.error, setactiveSection)
                setInventoryLocationErrors(errorData);
            }
        }
        else {
                setInventoryLocationErrors({});
                if (mode === "add") addInventoryLocation();
                if (mode === "update") updateInventoryLocation();
        }
    }

    return (
        <Modal id="inventory-location-modal-switch-handle" size="lg" isOpen={showInventoryLocationModel.show} toggle={toggle}>
            <ModalHeader toggle={toggle} className="bg-primary text-white">
                <span className="text-white h3">{mode === "update" ? "Update" : "Add"} Inventory Location</span>
            </ModalHeader>
            <ModalBody>
                <>
                    <Row className='px-3 m-0 mb-3 py-0'>
                        <ButtonGroup aria-label="Basic example">
                            <Button className={`${activeSection === "general" && "active"}`}
                                onClick={() => setactiveSection("general")}
                                outline color="primary">General</Button>
                            <Button className={`${activeSection === "shipFrom" && "active"}`}
                                onClick={() => setactiveSection("shipFrom")}
                                outline color="primary">Ship from Address</Button>
                            <Button className={`${activeSection === "shipTo" && "active"}`}
                                onClick={() => setactiveSection("shipTo")}
                                outline color="primary">Ship to Address</Button>
                        </ButtonGroup>
                    </Row>
                    <hr className='m-0 p-0 mx-n4'></hr>

                    {/* General section */}
                    {activeSection === "general" && <>
                        <Row className='p-3 my-1'>
                            <Col md={12}>
                                <FormGroup className='mb-2'>
                                    <CLabel required for="full_name" name="Full Name"></CLabel>
                                    <CInput
                                        errors={inventoryLocationErrors?.full_name}
                                        id="full_name" name="full_name"
                                        value={inventoryLocation.full_name}
                                        onChange={formInputFieldOnChangeHandler}
                                        placeholder="Location Name"
                                        type="text" />
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup className='mb-2'>
                                    <CLabel for="name" name="Short Name" />
                                    <CInput
                                        errors={inventoryLocationErrors?.name}
                                        id="name" value={inventoryLocation.name}
                                        onChange={formInputFieldOnChangeHandler}
                                        name="name"
                                        placeholder="Short Name"
                                        type="text" />
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup className='mb-2'>
                                    <CLabel required for="code" name="Code"></CLabel>
                                    <CInput
                                        errors={inventoryLocationErrors?.code}
                                        id="code"
                                        name="code"
                                        disabled={mode === "update" ? true : false}
                                        value={inventoryLocation.code}
                                        onChange={formInputFieldOnChangeHandler}
                                        placeholder="Code"
                                        type="text"
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <CLabel required for="state" name="State"></CLabel>
                                <CustomDropdown
                                    id="state"
                                    name="state"
                                    onChange={(value) => customDropdownOnChange(value, "state")}
                                    options={states || []} placeholder="Select State"
                                    isValid={true}
                                    errors={inventoryLocationErrors?.state}
                                    value={parseInt(inventoryLocation.state)} />
                            </Col>

                            <Col md={6}>
                                <CLabel required for="type" name="Type"></CLabel>
                                <CustomCreatableSelect
                                    value={{ value: inventoryLocation.type, label: inventoryLocation.type }}
                                    options={inventoryLocationTypes.data}
                                    errors={inventoryLocationErrors?.type}
                                    onChange={(newValue) => {
                                        newValue && setInventoryLocation({ ...inventoryLocation, type: newValue })
                                    }}
                                />
                            </Col>

                            <Col md={12} className="mt-3">
                                <Label for="autoAssign">Auto Assign</Label>
                                <br></br>
                                <Toggle className="modal-switch-handle"
                                    checked={inventoryLocation.is_auto_assignable}
                                    onChange={() => { setInventoryLocation({ ...inventoryLocation, is_auto_assignable: !inventoryLocation.is_auto_assignable }) }} />
                            </Col>
                        </Row>
                    </>}

                    {/* Ship from */}
                    {activeSection === "shipFrom" && <>
                        <Row className='p-3 my-1'>
                            <Col md={6}>
                                <FormGroup className='mb-2'>
                                    <CLabel for="ship_from_name" name="Name"></CLabel>
                                    <CInput
                                        errors={inventoryLocationErrors?.ship_from_name}
                                        id="ship_from_name"
                                        name="ship_from_name"
                                        onChange={formInputFieldOnChangeHandler}
                                        value={inventoryLocation.ship_from_name}
                                        placeholder="Name"
                                        type="text" />
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup className='mb-2'>
                                    <Label for="ship_from_phone">Phone Number</Label>
                                    <CInput
                                        errors={inventoryLocationErrors?.ship_from_phone}
                                        id="ship_from_phone"
                                        name="ship_from_phone"
                                        onChange={formInputFieldOnChangeHandler}
                                        value={inventoryLocation.ship_from_phone}
                                        placeholder="Phone Number"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        type="text" />
                                </FormGroup>
                            </Col>

                            <Col md={12}>
                                <FormGroup className='mb-2'>
                                    <Label for="ship_from_address1">Address 1</Label>
                                    <CInput
                                        errors={inventoryLocationErrors?.ship_from_address1}
                                        id="ship_from_address1"
                                        name="ship_from_address1"
                                        onChange={formInputFieldOnChangeHandler}
                                        value={inventoryLocation.ship_from_address1}
                                        placeholder="Address 1"
                                        type="text" />
                                </FormGroup>
                            </Col>

                            <Col md={12}>
                                <FormGroup className='mb-2'>
                                    <Label for="ship_from_address2">Address 2</Label>
                                    <CInput
                                        errors={inventoryLocationErrors?.ship_from_address2}
                                        id="ship_from_address2"
                                        name="ship_from_address2"
                                        onChange={formInputFieldOnChangeHandler}
                                        value={inventoryLocation.ship_from_address2}
                                        placeholder="Address 2"
                                        type="text" />
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup className='mb-2'>
                                    <Label for="ship_from_city">City</Label>
                                    <CInput
                                        errors={inventoryLocationErrors?.ship_from_city}
                                        id="ship_from_city"
                                        name="ship_from_city"
                                        onChange={formInputFieldOnChangeHandler}
                                        value={inventoryLocation.ship_from_city}
                                        placeholder="City"
                                        type="text" />
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup className='mb-2'>
                                    <CLabel required for="ship_from_state" name="State"></CLabel>
                                    <CustomDropdown
                                        id="ship_from_state"
                                        name="ship_from_state"
                                        onChange={(value) => customDropdownOnChange(value, "ship_from_state")}
                                        options={states || []}
                                        placeholder="Select State"
                                        isValid={true}
                                        errors={inventoryLocationErrors?.ship_from_state}
                                        value={inventoryLocation.ship_from_state} />
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup className='mb-2'>
                                    <Label for="ship_from_zip">Zip</Label>
                                    <CInput errors={inventoryLocationErrors?.ship_from_zip}
                                        id="ship_from_zip"
                                        name="ship_from_zip"
                                        placeholder="Zipcode"
                                        onChange={formInputFieldOnChangeHandler}
                                        value={inventoryLocation.ship_from_zip}
                                        type="text" />
                                </FormGroup>
                            </Col>
                        </Row>
                    </>}

                    {/* Ship to */}
                    {activeSection === "shipTo" &&
                        <>
                            <Row className='p-3 my-1'>
                                <Col md={6}>
                                    <FormGroup className='mb-2'>
                                        <Label for="ship_return_phone">Phone Number</Label>
                                        <CInput errors={inventoryLocationErrors?.ship_return_phone}
                                            id="ship_return_phone"
                                            name="ship_return_phone"
                                            onChange={formInputFieldOnChangeHandler}
                                            value={inventoryLocation.ship_return_phone}
                                            placeholder="Phone Number"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            type="text" />
                                    </FormGroup>
                                </Col>

                                <Col md={12}>
                                    <FormGroup className='mb-2'>
                                        <Label for="ship_return_address1">Address 1</Label>
                                        <CInput
                                            errors={inventoryLocationErrors?.ship_return_address1}
                                            id="ship_return_address1"
                                            name="ship_return_address1"
                                            onChange={formInputFieldOnChangeHandler}
                                            value={inventoryLocation.ship_return_address1}
                                            placeholder="Address 1"
                                            type="text" />
                                    </FormGroup>
                                </Col>

                                <Col md={12}>
                                    <FormGroup className='mb-2'>
                                        <Label for="ship_return_address2">Address 2</Label>
                                        <CInput
                                            errors={inventoryLocationErrors?.ship_return_address2}
                                            id="ship_return_address2" name="ship_return_address2"
                                            onChange={formInputFieldOnChangeHandler}
                                            value={inventoryLocation.ship_return_address2}
                                            placeholder="Address 2"
                                            type="text" />
                                    </FormGroup>
                                </Col>

                                <Col md={6}>
                                    <FormGroup className='mb-2'>
                                        <Label for="ship_return_city">City</Label>
                                        <CInput
                                            errors={inventoryLocationErrors?.ship_return_city}
                                            id="ship_return_city"
                                            name="ship_return_city"
                                            onChange={formInputFieldOnChangeHandler}
                                            value={inventoryLocation.ship_return_city}
                                            placeholder="City"
                                            type="text" />
                                    </FormGroup>
                                </Col>

                                <Col md={6}>
                                    <FormGroup className='mb-2'>
                                        <CLabel for="ship_return_state" name="State" required></CLabel>
                                        <CustomDropdown
                                            id="ship_return_state"
                                            name="ship_return_state"
                                            onChange={(value) => customDropdownOnChange(value, "ship_return_state")}
                                            options={states || []}
                                            placeholder="Select State"
                                            isValid={true}
                                            errors={inventoryLocationErrors?.ship_return_state}
                                            value={inventoryLocation.ship_return_state} />
                                    </FormGroup>
                                </Col>

                                <Col md={6}>
                                    <FormGroup className='mb-2'>
                                        <Label for="ship_return_zip">Zip</Label>
                                        <CInput
                                            errors={inventoryLocationErrors?.ship_return_zip}
                                            id="ship_return_zip"
                                            name="ship_return_zip"
                                            onChange={formInputFieldOnChangeHandler}
                                            value={inventoryLocation.ship_return_zip}
                                            placeholder="Zipcode"
                                            type="text" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </>
                    }
                    <hr className='m-0 p-0 mx-n4'></hr>

                    {/* Update/Create Button */}
                    <Row className='flex-row-reverse mt-3 pr-3'>
                        <CButton type="submit" color='primary' onClick={() => { inventoryLocationFormSubmit() }} isLoading={isButtonLoading}>
                            {mode === "add" && "Submit"}
                            {mode === "update" && "Save"}
                        </CButton>
                    </Row>
                </>
            </ModalBody>

        </Modal>
    )
}
export default InventoryLocationDetailModal;