import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import Select from "react-select";
import MoneyFormatter from '../../components/Formatter/formatter'

const SubscriptionTableRow = ({ subscription, subscriptionItem, planOptions, brandOptions, handleBrandChange, handlePlanChange }) => {
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({ value: subscriptionItem?.plan, label: subscriptionItem?.plan });

    useEffect(() => {
        const initialSelectedBrands = subscription.brand_list.map(brand => ({
            value: brand.id,
            label: brand.name
        }));
        setSelectedBrands(initialSelectedBrands);
    }, [subscription]);

    const handleChange = selectedOptions => {
        setSelectedBrands(selectedOptions);
        handleBrandChange(subscription.id, subscriptionItem.id, selectedOptions);
    };

    const handlePlanOnclick = selectedOption => {
        setSelectedPlan(selectedOption);
        handlePlanChange(selectedOption, subscriptionItem.id);
    };

    return (
        <tr key={subscriptionItem.subscription_item_id}>
            <td className="pl-2 pr-2" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "250px" }} title={subscription.subscription_id}>
                <a href={window.env.APP_URL + `subscription/${subscription.subscription_id}`} className="btn btn-link p-0">
                    {subscription.subscription_id || "None"}
                </a>
            </td>
            <td className="pl-2 pr-2" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "200px" }} title={subscriptionItem.subscription_item_id}>
                <a href={window.env.APP_URL + `subscription-item/${subscriptionItem.subscription_item_id}`} className="btn btn-link p-0">
                    {subscriptionItem.subscription_item_id || "None"}
                </a>
            </td>
            <td className="pl-2 pr-2" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "200px" }} title={subscription.owner_name}>
                <a href={window.env.APP_URL + `user/change/${subscription.owner_id}`} className="btn btn-link p-0">
                    {subscription.owner_name || "None"}
                </a>
            </td>
            <td className="pl-2 pr-2">{subscription.owner_email || "-"}</td>
            <td className="pl-2 pr-2">
                {subscription.subscription_created_at ? (
                    <Moment format="MM/DD/YYYY" withTitle>
                        {subscription.subscription_created_at}
                    </Moment>
                ) : (
                    "-"
                )}
            </td>
            <td className="pl-2 pr-2">
                <span
                    className={`badge badge-pill ${
                        subscription.status === "active"
                            ? "badge-success"
                            : subscription.status === "unpaid"
                            ? "badge-primary"
                            : subscription.status === "past_due"
                            ? "badge-danger"
                            : subscription.status === "incomplete_expired"
                            ? "badge-danger"
                            : subscription.status === "trialing"
                            ? "badge-primary"
                            : ""
                    }`}>
                    {subscription.status || "-"}
                </span>
            </td>
            {/* <td>{subscription.subscription_plan || "-"}</td> */}
            {/* Monady Task: 6873707985 - Added Dropdown For Plans Column */}
            <td className="pl-2 pr-2" style={{ width: "200px" }}>
                <Select
                    value={selectedPlan}
                    onChange={handlePlanOnclick}
                    options={planOptions}
                    checked={true}
                    className="select-input"
                    placeholder="Select plan..."
                    styles={{
                        control: (base) => ({
                            ...base,
                            minWidth: '150px', // Minimum width of the dropdown
                            maxWidth: '100%', // Allows expansion
                        }),
                    }}
                />
            </td>
            {/* Monday Task: 6797020667 - Added Dropdown For Brands Column */}
            <td className="pl-2 pr-2" style={{ width: "300px" }}>
                <Select
                    className="select-input"
                    placeholder="Select brands..."
                    isMulti
                    value={selectedBrands}
                    onChange={handleChange}
                    options={brandOptions}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    allowSelectAll={true}
                    checked={true}
                    styles={{
                        control: (base) => ({
                            ...base,
                            minWidth: '250px', // Minimum width of the dropdown
                            maxWidth: '100%', // Allows expansion
                        }),
                    }}
                />
            </td>
            <td>{selectedBrands?.length || "-"}</td>
            <td><MoneyFormatter value={subscription?.invoice_amount ? subscription?.invoice_amount : 0}/></td>
            <td><MoneyFormatter value={subscriptionItem?.amount ? subscriptionItem?.amount : 0}/></td>
            <td><MoneyFormatter value={subscriptionItem?.discount_amount ? subscriptionItem?.discount_amount : 0}/></td>
            <td><MoneyFormatter value={subscriptionItem?.due_amount ? subscriptionItem?.due_amount : 0}/></td>
            <td>{subscriptionItem?.frequency ? subscriptionItem?.frequency : "-"}</td>
            <td>
              <span className={"badge badge-primary"}>
                {subscription?.collection_method ? subscription?.collection_method : "-"}
              </span>
            </td>
        </tr>
    );
};
export default SubscriptionTableRow;
