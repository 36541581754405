import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Container, Row} from "reactstrap";
import Filter from "../Filter";
import LabelFilter from "../Label";
import Bottle from "../Bottle";
import DateRange from "../DateRange";
import OrderCount from "../OrderCount";
import StatusAge from "../../../components/Filters/StatusAge";
import OrderAge from "../../../components/Filters/OrderAge";

const navMorefilters = {
    background: '#fff',
    width: 300,
    position: 'fixed',
    right: 0,
    top: 0,
    marginTop: 0,
    zIndex: 9999,
    height: '100%'
}

const MoreFilters = (props) => {
    const [open, setOpen] = useState(false);
    const [sendRequestForMoreFiltersApi, setSendRequestForMoreFiltersApi] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {

            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setOpen(false)

            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, []);

    const toggleMoreFiltersDrawer = () => {
        setOpen(!open)
    }

    useEffect(() => {
      if(open === true) {
        setSendRequestForMoreFiltersApi(true);
      }
    }, [open])

    // More filters : Soered alphabatically as label
    // 1    Assigne
    // 2    Bottle
    // 3    Brand
    // 4    DateRange
    // 5    Discount Code
    // 6    Fulfillment
    // 7    Label
    // 8    Order Count
    // 9    Status Age
    // 10   Order Age
    // 11   Payment Status
    // 12   Product
    // 13   Product Types
    // 14   Referring Sites
    // 15   Sales Channel
    // 16   Shipping Rate
    // 17   SKU's
    // 18   State
    // 19   Status
    // 20   UTM Campaign
    // 21   UTM Medium
    // 22   UTM Source
    // 23   Reship Status
   
    return (
        <>
            <Button className='p-10px py-8px'  color={props.backgroundColor ? props.backgroundColor : "primary" } size={"sm"} onClick={toggleMoreFiltersDrawer}>
                <i style={{ fontSize : "10px" }} className={`fas fa-filter ${props.textColor && props.textColor }`}></i> <span style={{ fontSize : "10px" }} className={`d-none d-lg-inline-block ${props.textColor && props.textColor}` }>More filters</span>
            </Button>

            {sendRequestForMoreFiltersApi && 
                <div ref={wrapperRef} className={`more-filters ${!open && "d-none"}`}>
                    <Container className="container-class">
                        <Row className='row-heading-class'>
                            <Col xs={10} lg={9}>
                                <h3 className="text-primary p-0 m-0 text-left"><i className="fas fa-filter"></i> More filters</h3>

                            </Col>
                            <Col xs={2} lg={3} className='d-flex justify-content-center'>
                                <button type="button" onClick={toggleMoreFiltersDrawer} className="close btn-more-filters " data-dismiss="alert"
                                        aria-label="Close">
                                    <span aria-hidden="true" className="">×</span>
                                </button>


                            </Col>
                        </Row>
                        <hr className={"mt-2 mb-2"}/>
                        <Row className='row-filters-class'>
                            {props.onChange.assignee && (
                                <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.assignee?.hide && "d-lg-none"}`}>
                                    <Filter name={"Assignee"}
                                            prefix={"assignee"}
                                            color={"primary"}
                                            onChange={props.onChange.assignee}
                                            newparams={props.params}
                                            selected={props.filter.assignees}
                                            url={"/order/filter/assignee/"}
                                            onClear={props.onClear.assignee}
                                    />
                            </Col>)}

                            {props.onChange.reship_status && (
                                <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.reship_status?.hide && "d-lg-none"}`}>
                                    <Filter name={"Reship Status"}
                                            prefix={"reship_status"}
                                            color={"primary"}
                                            onChange={props.onChange.reship_status}
                                            newparams={props.params}
                                            selected={props.filter.reshipStatuses}
                                            url={"/order/filter/reship-status/"}
                                            onClear={props.onClear.reshipStatuses}
                                    />
                            </Col>)}

                            {props.onChange.bottle && (
                                <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.bottle?.hide && "d-lg-none"}`}>
                                {props.user.isAdmin &&
                                <Bottle
                                    color={"primary"}
                                    onSubmit={props.onChange.bottle}
                                    valueMin={props.filter.bottleMin}
                                    valueMax={props.filter.bottleMax}
                                    refMinBottle={props.minBottle}
                                    refMaxBottle={props.maxBottle}
                                />}
                            </Col>)}

                            {props.onChange.brand && (
                            <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.brand?.hide && "d-lg-none"}`}>
                                <Filter name={"Brand"}
                                        prefix={"brand"}
                                        color={"primary"}
                                        onChange={ props.onChange.brand}
                                        newparams={ props.params}
                                        selected={ props.filter.brands}
                                        url={"/order/filter/brand/"}
                                        onClear={props.onClear.brand}
                                />
                            </Col>)}

                            {props.onChange.dateRange && (
                            <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.dateRange?.hide && "d-lg-none"}`}>
                                <DateRange
                                    startDate={props?.filter?.startDate}
                                    endDate={props?.filter?.endDate}
                                    color={"primary"}
                                    onCallback={ props.onChange.dateRange}></DateRange>
                            </Col>)}

                            {props.onChange.discountCode && (
                            <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.discountCode?.hide && "d-lg-none"}`}>
                                <Filter name={"Discount Code"}
                                        color={"primary"}
                                        prefix={"discount_code"}
                                        onChange={props.onChange.discountCode}
                                        newparams={props.params}
                                        selected={props.filter.discountCodes}
                                        url={"/order/filter/discount-code/"}
                                        onClear={props.onClear.discount_code}
                                />
                            </Col>)}

                            {props.onChange.fulfillment && (
                            <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.fulfillment?.hide && "d-lg-none"}`}>
                                <Filter name={"Fulfillment"}
                                        prefix={"fulfillment"}
                                        color={"primary"}
                                        onChange={ props.onChange.fulfillment}
                                        newparams={ props.params}
                                        selected={ props.filter.fulfillments}
                                        url={"/order/filter/fulfillment/"}
                                        inputSearch={false}
                                        onClear={props.onClear.fulfillment}
                                />
                            </Col>)}

                            {props.onChange.productCategory && (
                                <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.productCategory?.hide && "d-lg-none"}`}>
                                    <Filter
                                        name={"Product Category"}
                                        color={"primary"}
                                        prefix={"productCategory"}
                                        onChange={props?.onChange?.productCategory}
                                        newparams={props?.params}
                                        selected={props?.filter?.productCategory}
                                        url={"order/filter/product-category/"}
                                        inputSearch={false}
                                        onClear={props?.onClear?.productCategory}
                                    />
                                </Col>)}

                            {props.onChange.label && (
                            <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.label?.hide && "d-lg-none"}`}>
                                <LabelFilter
                                    color={"primary"}
                                    onChange={props.onChange.label}
                                    selected={props.filter.labels}
                                />
                            </Col>)}

                            {props.orderCountData && props.orderCountData &&
                            (<Col xs={12} className={"pt-3"}>
                                <OrderCount name={"order count"}
                                    prefix={"orderCount"}
                                    color={"primary"}
                                    onChange={props.onChange.orderCount}
                                    orderCountData={props.orderCountData}
                                    selected={props.filter.orderCount}
                                    inputSearch={true}
                                    onClear={props.onClear.orderCount}
                                    />
                            </Col>)
                            }
                            
                            {props.onChange.statusAge && (
                                <Col xs={12} className='pt-3'>
                                    <StatusAge
                                        color={"primary"}
                                        onSubmit={props.onChange.statusAge}
                                        valueMin={props.filter.StatusDayMin}
                                        valueMax={props.filter.StatusDayMax}
                                    />
                                </Col>
                            )}
                            
                            {props.onChange.orderAge && (  
                                <Col xs={12} className='pt-3'>   
                                    <OrderAge
                                        color={"primary"}
                                        onSubmit={props.onChange.orderAge}
                                        valueMin={props.filter.OrderDayMin}
                                        valueMax={props.filter.OrderDayMax}
                                    />
                                </Col>
                            )}

                            {props.onChange.paymentStatus && (
                            <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.financial_status?.hide && "d-lg-none"}`}>
                                <Filter name={"Payment Status"}
                                        prefix={"payment_status"}
                                        color={"primary"}
                                        onChange={ props.onChange.paymentStatus}
                                        newparams={ props.params}
                                        selected={ props.filter.financialStatus}
                                        url={"/order/filter/financial-status/"}
                                        inputSearch={false}
                                        onClear={props.onClear.paymentStatus}
                                />
                            </Col>)} 

                            {props.onChange.product && (
                            <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.product?.hide && "d-lg-none"}`}>
                                <Filter name={"Product"}
                                        prefix={"product"}
                                        color={"primary"}
                                        onChange={ props.onChange.product}
                                        newparams={ props.params}
                                        selected={ props.filter.products}
                                        url={"/order/filter/product/"}
                                        onClear={props.onClear.product}
                                />
                            </Col>)}

                            {props.onChange.productTypes && (
                            <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.productTypes?.hide && "d-lg-none"}`}>
                                <Filter
                                    name={"Product Type"}
                                    color={"primary"}
                                    prefix={"product_type"}
                                    onChange={props.onChange.productTypes}
                                    newparams={props.params}
                                    selected={props.filter.productTypes}
                                    url={"/order/filter/product_type/"}
                                    onClear={props.onClear.productTypes}
                                />
                            </Col>)}

                            {props.onChange.referringSites && (
                            <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.referringSites?.hide && "d-lg-none"}`}>
                                <Filter name={"Referring Sites"}
                                        color={"primary"}
                                        prefix={"referringSites"}
                                        onChange={props.onChange.referringSites}
                                        newparams={props.params}
                                        selected={props.filter.referringSites}
                                        url={"/order/filter/referring-sites/"}
                                        onClear={props.onClear.referringSites}
                                />
                            </Col>)}

                            {props.onChange.channel && (
                            <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.channel?.hide && "d-lg-none"}`}>
                                <Filter name={"Sales Channel"}
                                        color={"primary"}
                                        prefix={"sales_channel"}
                                        onChange={props.onChange.channel}
                                        newparams={props.params}
                                        selected={props.filter.channels}
                                        url={"/order/filter/channel/"}
                                        onClear={props.onClear.channel}
                                />
                            </Col>)}
                            
                            {props.onChange.shippingRate && (
                            <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.shippingRate?.hide && "d-lg-none"}`}>
                                <Filter name={"Shipping Rate"}
                                        color={"primary"}
                                        prefix={"shippingRate"}
                                        onChange={props.onChange.shippingRate}
                                        newparams={props.params}
                                        selected={props.filter.shippingRate}
                                        url={"/order/filter/shipping-rate/"}
                                        onClear={props.onClear.shippingRate}
                                />
                            </Col>)}

                            {props.onChange.sku && (
                            <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.sku?.hide && "d-lg-none"}`}>
                                <Filter name={"SKUs"}
                                        color={"primary"}
                                        prefix={"sku"}
                                        onChange={props.onChange.sku}
                                        newparams={props.params}
                                        selected={props.filter.sku}
                                        url={"/order/filter/sku/"}
                                        onClear={props.onClear.sku}
                                />
                            </Col>)}

                            {props.onChange.state && (
                            <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.state?.hide && "d-lg-none"}`}>
                                <Filter name={"State"}
                                        color={"primary"}
                                        prefix={"state"}
                                        onChange={props.onChange.state}
                                        newparams={props.params}
                                        selected={props.filter.states}
                                        url={"/order/filter/state/"}
                                        onClear={props.onClear.state}
                                />
                            </Col>)}

                            {props.onChange.bulkOrder && (
                            <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.bulkOrder?.hide && "d-lg-none"}`}>
                                <Filter name={"Bulk order"}
                                        color={"primary"}
                                        prefix={"bulk_order"}
                                        onChange={props.onChange.bulkOrder}
                                        newparams={props.params}
                                        selected={props.filter.bulkOrders}
                                        url={"/order/filter/bulk-order/"}
                                        onClear={props.onClear.bulk_order}
                                />
                            </Col>)}
                            {props.onChange.status && (
                            <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.status?.hide && "d-lg-none"}`}>
                                <Filter name={"Status"}
                                        prefix={"status"}
                                        color={"primary"}
                                        onChange={props.onChange.status}
                                        newparams={props.params}
                                        selected={props.filter.statuses}
                                        url={"/order/filter/status/"}
                                        inputSearch={false}
                                        onClear={props.onClear.status}
                                />
                            </Col>)}

                            {props.onChange.utmCampaign && (
                            <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.utmCampaign?.hide && "d-lg-none"}`}>
                                <Filter name={"UTM Campaign"}
                                        color={"primary"}
                                        prefix={"utm_campaign"}
                                        onChange={props.onChange.utmCampaign}
                                        newparams={props.params}
                                        selected={props.filter.utmsCampaign}
                                        url={"/order/filter/utm-campaign/"}
                                        onClear={props.onClear.utm_campaign}
                                />
                            </Col>)}

                            {props.onChange.utmMedium && (
                            <Col xs={12} className={`pt-3 ${props?.moreFiltersOnDesktop?.utmMedium?.hide && "d-lg-none"}`}>
                                <Filter name={"UTM Medium"}
                                        color={"primary"}
                                        prefix={"utm_medium"}
                                        onChange={props.onChange.utmMedium}
                                        newparams={props.params}
                                        selected={props.filter.utmsMedium}
                                        url={"/order/filter/utm-medium/"}
                                        onClear={props.onClear.utm_medium}
                                />
                            </Col>)}

                            {props.onChange.utm && (
                            <Col xs={12} className={`pt-3 pb-3 ${props?.moreFiltersOnDesktop?.utm?.hide && "d-lg-none"}`}>
                                <Filter name={"UTM Source"}
                                        color={"primary"}
                                        prefix={"utm"}
                                        onChange={props.onChange.utm}
                                        newparams={props.params}
                                        selected={props.filter.utms}
                                        url={"/order/filter/utm/"}
                                        onClear={props.onClear.utm}
                                />
                            </Col>)}
                        </Row>
                    </Container>
                </div>
            }
        </>
    );
};

export default MoreFilters;