import React, { useState} from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import '../../../assets/css/daterangepicker-custom-styles.css';
import moment from "moment/moment";

const DateRange = ({startDate, endDate, onCallback, button_name, color='white'}) => {
    const [settingsDate, setSettingsDate] = useState({
        startDate: startDate ? startDate : moment().subtract(30, 'days').format("M/D/Y"),
        endDate: endDate ? endDate : moment().format("M/D/Y"),
        ranges: {
            Today: [moment().toDate(), moment().toDate()],
            Yesterday: [
                moment().subtract(1, 'days').toDate(),
                moment().subtract(1, 'days').toDate(),
            ],
            'Last 7 Days': [
                moment().subtract(6, 'days').toDate(),
                moment().toDate(),
            ],
            'Last 30 Days': [
                moment().subtract(29, 'days').toDate(),
                moment().toDate(),
            ],
            'This Month': [
                moment().startOf('month').toDate(),
                moment().endOf('month').toDate(),
            ],
            'Last Month': [
                moment().subtract(1, 'month').startOf('month').toDate(),
                moment().subtract(1, 'month').endOf('month').toDate(),
            ],
        },
    })

    return (
        <DateRangePicker
            initialSettings={settingsDate}
            onCallback={onCallback}
        >
            <button type="button" className={ color === 'white' ? "dropdown-toggle btn btn-white btn-sm text-primary p-10px" : "dropdown-toggle btn btn-primary btn-sm p-10px"} style={{ fontSize : "10px"}}>
                { button_name ? button_name : 'Date' } 
            </button>
        </DateRangePicker>
    );
};

export default DateRange;