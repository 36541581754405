/*

  Author - Akshay Vishwakarma
  Monday Id - 6618268581
  Description - This file contrains code related to section one of product page.
  
 */

import React, { useEffect, useState } from "react";
import styles from "./createProduct.module.css";
import { CustomDropdown } from "components/CustomDropdown/CustomDropdown";
import RichTextEditor from "components/RichTextEditor";
import CreatableSelect from "react-select/creatable";

const createOption = label => ({
    label,
    value: label.toLowerCase().replace(/\W/g, "")
});

const SectionOne = ({ setPayload, payload, listing, setListing, requiredFields }) => {
    const [content, setContent] = useState();
    const [isLoading, setIsLoading] = useState(false);
    //const [value, setValue] = useState();
    //const [productTypevalue, setProductTypeValue] = useState();

    const handleKeyDown = (e, field) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };

    const handleChange = (field, value) => {
        if (field === "description") {
            setContent(value);
        }
        // if (field === "brand") {
        //     setValue(value);
        // }

        // setPayload({ ...payload, [field]: field === "brand" || field === "product_type" ? (value !== null ? value : "") : value });
        setPayload(prevPayload => ({
            ...prevPayload,
            [field]: field === "brand" || field === "product_type" ? (value !== null ? value : null) : value,
            newlyCreatedBrand: field === "brand" ? null : prevPayload.newlyCreatedBrand
        }));
    };

    const handleCreate = (key, listKey, newKey, inputValue) => {
        setIsLoading(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoading(false);
            setListing({ ...listing, [listKey]: [...listing[listKey], newOption] });

            if (key === "brand") {
                //setValue(newOption);
                setPayload({ ...payload, [key]: newOption, [newKey]: newOption });
            } else if (key === "product_type") {
                //setProductTypeValue(newOption);
                setPayload({ ...payload, [key]: newOption, [newKey]: newOption });
            }
        }, 1000);
    };

    const handelTagCreate = (key, listKey, newKey, inputValue) => {
        setIsLoading(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoading(false);
            setListing({ ...listing, [listKey]: [...listing[listKey], newOption] });
            setPayload({ ...payload, [key]: [...payload.tags, newOption], [newKey]: newOption });
        }, 1000);
    };

    useEffect(() => {
        setContent(payload.description);
    }, [payload.description]);

    return (
        <div>
            <div className={styles.sectionConatiner}>
                <div className={styles.productSectionOne}>
                    <div style={{ marginBottom: "1rem" }}>
                        <h3>Title *</h3>
                        <div className={styles.inputContainer}>
                            <input
                                type="text"
                                id="title"
                                placeholder="Title"
                                name="title"
                                className={styles.inputStyle}
                                onChange={e => handleChange("title", e.target.value)}
                                onKeyDown={e => handleKeyDown(e, "title")}
                                value={payload?.title}
                            />
                        </div>
                        {requiredFields.title ? (
                            <div>
                                <span style={{ color: "red", marginTop: "5px" }}>* This field is required</span>
                            </div>
                        ) : null}
                    </div>
                    <div style={{ marginBottom: "1rem" }}>
                        <h3>Description</h3>
                        <RichTextEditor onChange={content => handleChange("description", content)} defaultValue={content} />
                    </div>
                    <div style={{ marginBottom: "1rem" }} className={styles.gridContainer}>
                        <div>
                            <h3>ABV % *</h3>
                            <div className={styles.inputContainer}>
                                <input
                                    type="number"
                                    id="abv"
                                    placeholder="ABV %"
                                    name="abv"
                                    className={styles.inputStyle}
                                    value={payload.abv}
                                    min={0}
                                    onChange={e => {
                                        const value = e.target.value;
                                        if (value === "" || (parseInt(value) >= 0 && value[0] !== "-")) {
                                            handleChange("abv", e.target.value);
                                        } else {
                                            e.target.value = "";
                                        }
                                    }}
                                    onKeyDown={e => handleKeyDown(e, "abv")}
                                    onWheel={e => {
                                        e.preventDefault();
                                        e.target.blur();
                                        setTimeout(() => e.target.focus(), 0);
                                    }}
                                />
                            </div>
                            {requiredFields.abv ? (
                                <div>
                                    <span style={{ color: "red", marginTop: "5px" }}>* This field is required</span>
                                </div>
                            ) : !requiredFields.abv && payload.abv > 79 ? (
                                <span style={{ color: "red", marginTop: "5px" }}>ABV cannot be greater than 79%</span>
                            ) : null}
                        </div>
                        <div>
                            <h3>Brand *</h3>
                            <div className={styles.inputContainer}>
                                <CreatableSelect
                                    isClearable
                                    isDisabled={isLoading}
                                    isLoading={isLoading}
                                    placeholder="Select brand..."
                                    onChange={value => handleChange("brand", value)}
                                    onCreateOption={value => handleCreate("brand", "brandList", "newlyCreatedBrand", value)}
                                    options={listing.brandList}
                                    value={payload?.brand}
                                />
                            </div>
                            {requiredFields.brand ? (
                                <div>
                                    <span style={{ color: "red", marginTop: "5px" }}>* This field is required</span>
                                </div>
                            ) : null}
                        </div>
                        <div>
                            <h3>Product Category *</h3>
                            <div className={styles.inputContainer}>
                                <CustomDropdown
                                    className="select-input"
                                    placeholder="Select product Category..."
                                    options={listing.productCategoryList}
                                    isValid={true}
                                    onChange={value => handleChange("product_category", value)}
                                    value={payload?.product_category}
                                />
                            </div>
                            {requiredFields.product_category ? (
                                <div>
                                    <span style={{ color: "red", marginTop: "5px" }}>* This field is required</span>
                                </div>
                            ) : null}
                        </div>
                        <div>
                            <h3>Product Type *</h3>
                            <div className={styles.inputContainer}>
                                <CreatableSelect
                                    isClearable
                                    isDisabled={isLoading}
                                    isLoading={isLoading}
                                    placeholder="Select Product Type..."
                                    onChange={value => handleChange("product_type", value)}
                                    onCreateOption={value => handleCreate("product_type", "productTypeList", "new_product_type", value)}
                                    options={listing.productTypeList}
                                    value={payload?.product_type}
                                />
                            </div>
                            {requiredFields.product_type ? (
                                <div>
                                    <span style={{ color: "red", marginTop: "5px" }}>* This field is required</span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div style={{ marginBottom: "1rem" }}>
                        <div>
                            <h3>Tags</h3>
                            <div className={styles.inputContainer}>
                                <CreatableSelect
                                    isClearable
                                    isMulti
                                    isDisabled={isLoading}
                                    isLoading={isLoading}
                                    placeholder="Select tags..."
                                    onChange={value => handleChange("tags", value)}
                                    onCreateOption={value => handelTagCreate("tags", "tagsList", "new_tag", value)}
                                    options={listing.tagsList}
                                    value={payload.tags}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionOne;
