import React, { useContext, useEffect, useState } from "react";
import { Card, CardHeader, Container, Row, Col, Button } from "reactstrap";
import { api } from "services/api";
import { AuthContext } from "contexts/AuthContext";
import useFilter from "customHooks/useFilter";
import { getParams } from "utils/commonFunctions";
import PagePagination from "feature/pagination/Pagination";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import ChildOrderTable from "./ChildOrderTable";
import { CreateChildOrderModal } from "./CreateChildOrder";
import Filter from "components/Filters/Filter";
import DateRange from "../../components/Filters/DateRange";
import { onFilterChange, clearFilter, removeBadge } from "utils/commonFunctions";
import { removeAllURLParams, addURLParam, processURLSearchParams } from "../../utils/urls";
import BadgePillFilter from "components/Filters/Badge";

const ManualChildOrders = props => {
    const [childOrderData, setChildOrderData] = useState([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [page, setPage] = useState(1);
    const [params, setParams] = useState([]);
    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return [];
        }
    });
    const [isLoading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [selected, setSelected] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [refresh, setRefresh] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [clearall, setClearAll] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [filter, setFilter, clearFilterState] = useFilter({});

    // Check weather screen size is mobile or desktop
    const isMobile = useMobileDetection(mobileScreenThresholdValue);

    useEffect(() => {
        fetchManualChildOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, filter, refresh]);

    const searchInput = document.getElementById("manualChildOrderSearchInput");

    const fetchManualChildOrders = () => {
        setLoading(true);
        let new_params = getParams(filter);
        new_params = { ...new_params, page };
        api.get("order/flaviar/one-to-many/manual-child-order", { params: new_params })
            .then(response => {
                const list = response.data.results.map(order => {
                    const s = selected.filter(item => parseInt(item.id) === order.id);
                    order.checked = s.length > 0;
                    return order;
                });
                setChildOrderData(list);
                setTotalOrders(response.data.count);
                const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
                const previous = page !== 1 ? page - 1 : 1;
                const next = page !== totalPages ? page + 1 : totalPages;
                setPagination({
                    previous,
                    next,
                    pages: () => {
                        let startCount = 1;
                        let endCount = isMobile ? 4 : 12;
                        let numberCount = Math.round(endCount / 2);
                        const pageList = [];
                        if (numberCount < 0) numberCount = 1;
                        startCount = page - numberCount;
                        if (startCount <= 0) startCount = 1;
                        if (page !== 1) endCount = page + numberCount;
                        if (endCount > totalPages) endCount = totalPages;
                        if (totalPages >= endCount) {
                            for (let i = startCount; i <= endCount; i++) {
                                pageList.push(i);
                            }
                        } else if (totalPages >= 1) {
                            for (let i = 1; i <= totalPages; i++) pageList.push(i);
                        }
                        return pageList;
                    }
                });
                setLoading(false);
                delete new_params["page"];
                delete new_params["ordering"];
                setParams(new_params);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handlePagination = page => {
        setPage(page);
    };

    let timer = null;
    const handleSearch = e => {
        addURLParam(window, "search", e.target.value);
        clearTimeout(timer);
        timer = setTimeout(function () {
            setFilter({
                ...filter,
                search: e.target.value
            });
        }, 750);
    };

    const { user, isAuthentication } = useContext(AuthContext);

    const handleRefresh = () => {
        // setRefresh(!refresh);
        fetchManualChildOrders();
    };

    const handleDateRange = (start, end) => {
        setFilter({
            ...filter,
            dataSubmittedAtStart: start,
            dataSubmittedAtEnd: end
        });
        addURLParam(window, "dataSubmittedAtStart", start);
        addURLParam(window, "dataSubmittedAtEnd", end);
    };

    if (isAuthentication && !user.isAdmin) window.location.href = window.env.APP_URL;
    return (
        <>
            {isAuthentication && user.isAdmin && (
                <Container fluid>
                    <div className={"pt-7"}>
                        {isAuthentication && (
                            <Row className={"mb-2"}>
                                <Col xs={10} lg={"2"} className={"text-left"}>
                                    <input
                                        type="text"
                                        className={"form-control form-control-sm"}
                                        placeholder={"Search Existing Child Order"}
                                        id="manualChildOrderSearchInput"
                                        defaultValue={filter.search}
                                        onChange={handleSearch}
                                        newparams={""}
                                    />
                                </Col>
                                <Col xs={2} lg={"10"} className={"text-right"}>
                                    <div className={"d-none d-lg-inline-block mr-2"}>
                                        <div className="mb-1 d-inline">
                                            <button className="btn btn-neutral btn-sm mr-2 p-9px" onClick={handleRefresh}>
                                                <i className="fas fa-sync-alt"></i>
                                            </button>
                                        </div>

                                        <Filter
                                            name={"Child Order Type"}
                                            prefix={"childOrderType"}
                                            onChange={e => onFilterChange(e, "childOrderType", filter, setFilter)}
                                            newparams={params}
                                            selected={filter.childOrderType}
                                            inputSearch={false}
                                            url={"order/flaviar/one-to-many/filter/child-order-type"}
                                            onClear={() => clearFilter("childOrderType", setFilter, setClearAll)}
                                        />

                                        <Filter
                                            name={"Batch Status"}
                                            prefix={"batchStatus"}
                                            onChange={e => onFilterChange(e, "batchStatus", filter, setFilter)}
                                            newparams={params}
                                            selected={filter.batchStatus}
                                            inputSearch={false}
                                            url={"order/flaviar/one-to-many/filter/batch-status"}
                                            onClear={() => clearFilter("batchStatus", setFilter, setClearAll)}
                                        />

                                        <DateRange onCallback={handleDateRange} button_name="Date Submitted"></DateRange>

                                        <Button className="p-9px" color={"primary"} size={"sm"} onClick={e => setOpenModal(true)}>
                                            <i className="fas fa-file-csv"></i> Generate Child Order
                                        </Button>

                                        <CreateChildOrderModal
                                            isOpen={openModal}
                                            toggle={() => setOpenModal(false)}
                                            loading={isLoading}
                                            handleRefresh={handleRefresh}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            {isAuthentication && (
                                <div className="col">
                                    <Card className="shadow ">
                                        <CardHeader className="border-0">
                                            <Row>
                                                <Col>
                                                    <h3
                                                        className="mb-2"
                                                        style={{
                                                            display: "inline-block",
                                                            paddingRight: "5px"
                                                        }}>
                                                        Manual Child Orders <small>&nbsp;({totalOrders})</small>
                                                    </h3>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col sm="12">
                                                    <BadgePillFilter
                                                        data={filter}
                                                        selected={selected}
                                                        onClick={e => {
                                                            removeBadge(e, filter, setFilter, selected, setSelected, () => {}, searchInput);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        {isLoading ? (
                                            <div className="text-center mt-3 mb-3">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <ChildOrderTable childOrderData={childOrderData} />
                                                <PagePagination
                                                    pagination={pagination}
                                                    handlePagination={handlePagination}
                                                    page={page}
                                                    totalCount={totalOrders}
                                                />
                                            </>
                                        )}
                                    </Card>
                                </div>
                            )}
                        </Row>
                    </div>
                </Container>
            )}
        </>
    );
};
export default ManualChildOrders;
