import React, {useEffect, useState} from "react";
import {Button, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label, UncontrolledDropdown} from "reactstrap";

const OrderCount = ({ color,inputSearch,selected, orderCountData,  onChange, name, prefix, onClear}) => {
   const [isOpen, setOpen] = useState(false);
   const [data, setData] = useState([]);

   const handleClose = () => {
     setOpen(!isOpen);
   };

    /**
     * Clear filter handler
     */
    const handleClear = () => {
      // Close dropdown
      setOpen(false);

      // Callback parent to handle clear
      onClear();
  };

   useEffect(() => {
     const countData = orderCountData.map((item) => {
       item["checked"] =
         selected?.length > 0 &&
         selected.find((selectedItem) => selectedItem.value == item.id)
           ? true
           : false;
       return item;
     });
     setData(countData);
   }, [selected]);

  return (
      <>      
        <UncontrolledDropdown 
         size={"sm"}
         isOpen={isOpen}
         toggle={() => handleClose()}
        >
          <DropdownToggle
            color={color}
            className={color === "white" ? "text-primary" : "p-10px"}
            caret
          >
            Order Count
          </DropdownToggle>
          <DropdownMenu className="more-filters-dropdown-menu" right>
            <ul
              className={"p-2 overflow-auto"}
              style={{ maxHeight: "300px" }}
              id={"filter_" + prefix}
            >
              {selected && selected.length > 0 && (
                <li className={"mb-3 "}>
                  <Button
                    color={"primary"}
                    size={"sm"}
                    block={true}
                    onClick={handleClear}
                  >
                    <i className="fas fa-times-circle"></i> Clear
                  </Button>
                </li>
              )}

              {data?.map((option, index) => (
                <li className={"list-unstyled "} key={index}>
                  <Label className={"text-sm"}>
                    <input
                      type="checkbox"
                      id={prefix + "_" + option.id}
                      className={"checkbox_" + prefix}
                      value={option.id}
                      onChange={(e)=> onChange(e, prefix)}
                      data-label={option.name}
                      checked={option.checked}
                    /> &nbsp;
                    {option.name}
                  </Label>
                </li>
              ))}
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
}

export default OrderCount;