import ShowError from "components/Errors";
import React, {useEffect, useState} from "react";
import {Button, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label, UncontrolledDropdown} from "reactstrap";

const StatusAge = ({color = 'white', onSubmit, valueMin, valueMax}) => {
    const [errors, seterrors] = useState({valueMin : '', valueMax : ''});
    const [values, setvalues] = useState({valueMin : valueMin, valueMax : valueMax});
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const handleStatusAgeChange = (e) => {
        e.preventDefault();
        const inputValue = e.target.value;
        // Check if the input value contains a decimal point and less than 0
        const hasDecimal = inputValue.includes(".");

        if (!hasDecimal && inputValue >= 0) {
            setvalues({ ...values, [e.target.name]: e.target.value });
            seterrors({ ...errors, [e.target.name]: "" });
        }
        seterrors({
            ...errors,
            [e.target.name]: !hasDecimal && e.target.value >= 0 ? "" : "Value can't be a decimal or less than 0",
        });
    }

    useEffect(() => {
        setvalues({valueMin, valueMax});
    }, [valueMin, valueMax])
    
    return (
        <UncontrolledDropdown size={"sm"} isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle
                color={color}
                className={(color === 'white') ? "text-primary p-10px" : "p-10px"}
                caret>
                Status Age
            </DropdownToggle>
            <DropdownMenu className="more-filters-dropdown-menu" right>

                <Form className={'p-2'} onSubmit={(e) => onSubmit(e, values.valueMin, values.valueMax)}>

                        <h4>Days Quantity</h4>
                        <hr className={'m-0 mb-2'}/>
                        <FormGroup className={'m-0 mb-1'}>
                            <Label className={"text-sm"} >
                                Min
                            </Label>
                                <Input type={"number"}
                                       step="1"
                                       bsSize={'sm'}
                                       name="valueMin"
                                       value={values.valueMin}
                                       placeholder={"Min"}
                                       data-label={"Min"}
                                       onChange={(e)=>handleStatusAgeChange(e)}
                                       defaultValue={values.valueMin}/>
                                {errors.valueMin && <ShowError message={errors.valueMin}/>}
                        </FormGroup>
                        <FormGroup className={'m-0 mb-1'}>
                            <Label className={"text-sm"}>
                                Max
                            </Label>
                            <Input type={"number"}
                                   step="1"
                                   bsSize={'sm'}
                                   name="valueMax"
                                   value={values.valueMax}
                                   placeholder={"Max"}
                                   data-label={"Max"}
                                   onChange={(e)=>handleStatusAgeChange(e)}
                                   defaultValue={values.valueMax}
                            />
                            {errors.valueMax && <ShowError message={errors.valueMax}/>}
                        </FormGroup>
                        <FormGroup className={'m-0 mt-3 text-right'}>
                            <Button onClick={toggle} size={'sm'}>Cancel</Button>
                            <Button type={'submit'} size={'sm'} color={'primary'} >Apply</Button>
                        </FormGroup>
                </Form>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

export default StatusAge;