import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, CardFooter, CardHeader, Col, Container, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";
import { api } from "services/api";
import Filter from "../../components/Filters/Filter";
import DateRange from "../../components/Filters/DateRange";
import BadgePillFilter from "../../components/Filters/Badge";
import Moment from "react-moment";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import useFilter from "customHooks/useFilter";
import { removeAllURLParams, addURLParam, removeURLParam } from "utils/urls";
import { getParams, removeBadge, onFilterChange, clearFilter, Sort, handleSort } from "../../utils/commonFunctions";
import { prefixAndFilterMapping } from "customHooks/useFilter";


const InvoiceChargeBacks = () => {
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter, clearFilterState] = useFilter({});
    const [clearall, setClearAll] = useState(false);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState("-id");
    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return [];
        }
    });
    const [params, setParams] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [invoiceChargebacks, setInvoiceChargeback] = useState([]);
    const [invoiceChargebackCount, setInvoiceChargebackCount] = useState([]);
    const [sortOrder, setSortOrder] = useState({ order: "", columnName: "" });

    const isMobile = useMobileDetection(mobileScreenThresholdValue);

    const handleRefresh = () => {
        setRefresh(!refresh);
    };

    const handleBrands = e => {
        let checkbox = [...new Set(filter.brands)];

        if (e.target.checked) {
            checkbox.push({ value: e.target.value, label: e.target.dataset.label });
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value);
        }

        setFilter({ ...filter, brands: checkbox });
    };

    const handleInvoiceStatus = e => {
        let checkbox = [...new Set(filter.invoiceStatus)];

        if (e.target.checked) {
            checkbox.push({ value: e.target.value, label: e.target.dataset.label });
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value);
        }

        setFilter({ ...filter, invoiceStatus: checkbox });
    };

    const handleInvoiceNumber = (e, prefix) => {
        /* Handling check & uncheck of invoice number filter in a list*/
        let checkbox = [...new Set(filter.invoiceNumbers)];

        if (e.target.checked) {
            checkbox.push({ value: e.target.value, label: e.target.dataset.label });
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value);
        }

        setFilter({ ...filter, invoiceNumbers: checkbox });
    };

    const handleClearAll = () => {
        setClearAll(false);
        clearFilterState();
    };

    const clearFilter = prefix => {
        if (prefix === "brand") {
            setFilter({ ...filter, brands: [] });
        }
        if (prefix === "invoice_status") {
            setFilter({ ...filter, invoiceStatus: [] });
        }
        if (prefix === "invoice_numbers") {
            setFilter({ ...filter, invoiceNumbers: [] });
        }
        //7114901778- Store Filters on Invoices chargebacks
        if (prefix === "store") {
            setFilter({ ...filter, store: [] });
        }
        removeURLParam(window, prefixAndFilterMapping[prefix])
    };

    useEffect(() => {
        setLoading(true);
        let new_params = getParams(filter);
        new_params = {
            ...new_params,
            page,
            ordering: sort
        };
        api.get("order/invoice-shipping-chargebacks/", {
            params: new_params
        })
            .then(response => {
                setInvoiceChargeback(response?.data?.results);
                setInvoiceChargebackCount(response?.data?.count);

                const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
                const previous = page !== 1 ? page - 1 : 1;
                const next = page !== totalPages ? page + 1 : totalPages;
                setPagination({
                    previous,
                    next,
                    pages: () => {
                        let startCount = 1;
                        let endCount = isMobile ? 4 : 12;
                        let numberCount = Math.round(endCount / 2);
                        const pageList = [];
                        if (numberCount < 0) numberCount = 1;
                        startCount = page - numberCount;
                        if (startCount <= 0) startCount = 1;
                        if (page !== 1) endCount = page + numberCount;
                        if (endCount > totalPages) endCount = totalPages;
                        if (totalPages >= endCount) {
                            for (let i = startCount; i <= endCount; i++) {
                                pageList.push(i);
                            }
                        } else if (totalPages >= 1) {
                            for (let i = 1; i <= totalPages; i++) {
                                pageList.push(i);
                            }
                        }
                        return pageList;
                    }
                });
                setLoading(false);
                delete new_params["page"];
                delete new_params["ordering"];
                setParams(new_params);
            })
            .catch(err => {
                console.log(err);
            });
    }, [page, sort, filter, refresh, isMobile]);

    const handlePagination = page => {
        setPage(page);
    };

    const handleDateRange = (start, end) => {
        setFilter({
            ...filter,
            // 6367053025 - Generic Date Filter bug changes
            InvoiceShippingStartDate: start,
            InvoiceShippingEndDate: end
        });
        addURLParam(window, "InvoiceShippingStartDate", start);
        addURLParam(window, "InvoiceShippingEndDate", end);
    };

    const createRemoveBadgeWrapper = (filter, setFilter) => e => {
        removeBadge(
            e,
            filter,
            setFilter,
            () => {},
            () => {},
            ""
        );
    };

    return (
        <>
            <Container fluid>
                <div className={"pt-7"}>
                    <Row className={"mb-2"}>
                        <Col xs={8} lg={2} className={"text-left"}></Col>
                        <Col className={"text-right"}>
                            <div className={"d-none d-lg-inline-block mr-2"}>
                                <button className="btn btn-neutral btn-sm p-9px" onClick={handleRefresh}>
                                    <i className="fas fa-sync-alt"></i>
                                </button>
                            </div>
                            <Filter
                                name={"Brand"}
                                prefix={"brand"}
                                onChange={e => onFilterChange(e, "brand", filter, setFilter)}
                                newparams={params}
                                selected={filter.brands}
                                url={"/order/filter/brand/"}
                                onClear={() => clearFilter("brand")}
                            />
                            <Filter
                                name={"Invoice Number"}
                                prefix={"invoiceNumbers"}
                                onChange={e => onFilterChange(e, "invoiceNumbers", filter, setFilter)}
                                newparams={params}
                                selected={filter.invoiceNumbers}
                                url={"order/invoice-shipping-chargebacks/filter/invoice-number/"}
                                onClear={() => clearFilter("invoice_numbers")}
                            />
                            <Filter
                                name={"Invoice Status"}
                                prefix={"invoice_status"}
                                onChange={handleInvoiceStatus}
                                newparams={params}
                                selected={filter.invoiceStatus}
                                url={"order/filter/invoice-status/"}
                                onClear={() => clearFilter("invoice_status")}
                            />
                            {/**7114901778- Store Filters on Invoices chargebacks*/}
                            <Filter
                                name={"Store"}
                                prefix={"store"}
                                onChange={e => onFilterChange(e, "store", filter, setFilter)}
                                newparams={params}
                                selected={filter.store}
                                url={"order/shipping-chargebacks/filter/store"}
                                onClear={() => clearFilter("store")}
                            />
                            <DateRange onCallback={handleDateRange} button_name="Invoice Date"></DateRange>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <div className="col">
                        <Card className="shadow ">
                            <CardHeader className="border-0">
                                <Row className="d-flex justify-content-between align-items-center p-1">
                                    <div className="d-flex">
                                        <h3 className="mb-0">
                                            Shipping Chargeback Invoices <small>({invoiceChargebackCount})</small>
                                        </h3>
                                        {clearall > 0 && (
                                            <a
                                                href={void 0}
                                                className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                                                onClick={handleClearAll}
                                                id="clear_all_filters">
                                                <i className="fas fa-times-circle"></i> Clear All filters
                                            </a>
                                        )}
                                    </div>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <BadgePillFilter data={filter} onClick={createRemoveBadgeWrapper(filter, setFilter)} />
                                    </Col>
                                </Row>
                            </CardHeader>
                            {isLoading ? (
                                <div className="text-center mt-3 mb-3">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col" className="pl-5">
                                                    <Sort onClick={() => handleSort(sort, "invoice_ref", setSortOrder, setSort)}>Invoice</Sort>
                                                </th>
                                                <th scope="col" className="">
                                                    Brand
                                                </th>
                                                <th scope="col" className=" ">
                                                    <Sort onClick={() => handleSort(sort, "created_at", setSortOrder, setSort)}>Invoice Date</Sort>
                                                </th>
                                                <th scope="col" className=" ">
                                                    <Sort onClick={() => handleSort(sort, "total", setSortOrder, setSort)}>Total($)</Sort>
                                                </th>
                                                <th scope="col" className=" ">
                                                    <Sort onClick={() => handleSort(sort, "status", setSortOrder, setSort)}>Status</Sort>
                                                </th>
                                                <th scope="col" className=" ">
                                                    Stripe Receipt
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoiceChargebacks.map(invoiceChargeback => (
                                                <tr key={invoiceChargeback.id}>
                                                    <td className="pl-5">
                                                        {invoiceChargeback?.invoice_number &&
                                                        invoiceChargeback?.invoice_number !== "" &&
                                                        invoiceChargeback?.invoice_number !== null ? (
                                                            <a href={"/shipping-chargebacks?invoiceNumbers=" + invoiceChargeback?.invoice_number}>
                                                                {invoiceChargeback?.invoice_number}
                                                            </a>
                                                        ) : (
                                                            <>-</>
                                                        )}
                                                    </td>
                                                    <td className=" pr-3">
                                                        {Array.isArray(invoiceChargeback?.shipping_chargeback_brands)
                                                            ? invoiceChargeback?.shipping_chargeback_brands.map((brand, index, array) => (
                                                                  <span key={index}>
                                                                      {brand}
                                                                      {index !== array.length - 1 ? ", " : ""}
                                                                      {(index + 1) % 3 === 0 && index !== array.length - 1 ? <br /> : ""}
                                                                  </span>
                                                              ))
                                                            : invoiceChargeback?.shipping_chargeback_brands}
                                                        {/* {invoiceChargeback?.shipping_chargeback_brands ? invoiceChargeback?.shipping_chargeback_brands : "-"} */}
                                                    </td>
                                                    <td className=" pr-6">
                                                        {invoiceChargeback?.created_at ? (
                                                            <Moment format="MM/D/YYYY" withTitle>
                                                                {invoiceChargeback.created_at}
                                                            </Moment>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </td>
                                                    <td className=" pr-3">{invoiceChargeback?.total ? invoiceChargeback?.total : "-"}</td>
                                                    <td className=" pr-1">{invoiceChargeback?.status ? invoiceChargeback?.status : "-"}</td>
                                                    <td className=" pr-1">
                                                        {invoiceChargeback?.invoice_domain !== null && invoiceChargeback?.invoice_number !== "" ? (
                                                            <a
                                                                href={invoiceChargeback?.invoice_domain + "/" + invoiceChargeback?.invoice_number}
                                                                className="btn btn-link p-0">
                                                                <i className={"fas fa-link"}></i>
                                                            </a>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <CardFooter className="text-center">
                                        <nav aria-label="...">
                                            {pagination.pages().length > 1 && (
                                                <Pagination className="pagination justify-content-center mb-0" listClassName="justify-content-center mb-0">
                                                    <PaginationItem className="">
                                                        <PaginationLink href="#" onClick={e => handlePagination(pagination?.previous)} tabIndex="-1">
                                                            <i className="fas fa-angle-left" />
                                                            <span className="sr-only">Previous</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                    {pagination.pages().map((pageIndex, index) => (
                                                        <PaginationItem key={index} className={page === pageIndex ? "active" : ""}>
                                                            <PaginationLink href="#" onClick={e => handlePagination(pageIndex)}>
                                                                {pageIndex}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    ))}
                                                    <PaginationItem>
                                                        <PaginationLink href="#" onClick={e => handlePagination(pagination?.next)}>
                                                            <i className="fas fa-angle-right" />
                                                            <span className="sr-only">Next</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                </Pagination>
                                            )}
                                        </nav>
                                    </CardFooter>
                                </>
                            )}
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};
export default InvoiceChargeBacks;
