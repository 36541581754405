import React, { useContext, useEffect, useState } from "react";
import { Card, CardFooter, CardHeader, Col, Container, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";
import { api } from "services/api";
import Filter from "../../components/Filters/Filter";
import DateRange from "../../components/Filters/DateRange";
import BadgePillFilter from "../../components/Filters/Badge";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import useFilter from "customHooks/useFilter";
import { Sort, handleSort } from "../../utils/commonFunctions";
import axios from "axios";
import { getParams, removeBadge, onFilterChange, clearFilter } from "../../utils/commonFunctions";
import { ComponentContext } from "../../contexts/ComponentContext";
import Moment from "react-moment";
import { useLocation } from "react-router-dom";
import { removeAllURLParams, addURLParam, processURLSearchParams } from "../../utils/urls";

const DiscountCodeChargebacksInvoice = () => {
    const [clearall, setClearAll] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [discountCodeInvoices, setDiscountCodeInvoices] = useState([]);
    const [discountCodeInvoicesCount, setDiscountCodeInvoicesCount] = useState([]);
    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return [];
        }
    });
    const [page, setPage] = useState(1);
    const isMobile = useMobileDetection(mobileScreenThresholdValue);
    const [sort, setSort] = useState("-id");
    const [refresh, setRefresh] = useState(false);
    const cancelToken = React.useRef(undefined);
    const [sortOrder, setSortOrder] = useState({ order: "", columnName: "" });
    const [filter, setFilter, clearFilterState] = useFilter({});
    const [params, setParams] = useState([]);
    const { component } = useContext(ComponentContext);

    /**
     * 6133415942-useEffect for listing API
     */
    useEffect(() => {
        setLoading(true);
        if (typeof cancelToken.current != undefined) {
            cancelToken.current !== undefined && cancelToken.current.cancel("Operation canceled due to new request.");
        }
        let new_params = getParams(filter);
        new_params = {
            ...new_params,
            page,
            ordering: sort
        };
        cancelToken.current = axios.CancelToken.source();
        api.get("discount-code-chargeback/invoice", {
            params: new_params
        }).then(response => {
            setDiscountCodeInvoices(response?.data?.results);
            setDiscountCodeInvoicesCount(response?.data?.count);
            const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
            const previous = page !== 1 ? page - 1 : 1;
            const next = page !== totalPages ? page + 1 : totalPages;
            setPagination({
                previous,
                next,
                pages: () => {
                    let startCount = 1;
                    let endCount = isMobile ? 4 : 12;
                    let numberCount = Math.round(endCount / 2);
                    const pageList = [];
                    if (numberCount < 0) numberCount = 1;
                    startCount = page - numberCount;
                    if (startCount <= 0) startCount = 1;
                    if (page !== 1) endCount = page + numberCount;
                    if (endCount > totalPages) endCount = totalPages;
                    if (totalPages >= endCount) {
                        for (let i = startCount; i <= endCount; i++) {
                            pageList.push(i);
                        }
                    } else if (totalPages >= 1) {
                        for (let i = 1; i <= totalPages; i++) {
                            pageList.push(i);
                        }
                    }
                    return pageList;
                }
            });
            setLoading(false);
        });
    }, [page, sort, filter, component, refresh, isMobile]);

    /**
     * 6133415942-Pagination Function
     * @param {*} page
     */
    const handlePagination = page => {
        setPage(page);
    };

    /**
     * 6133415942-Function for handling Refresh action
     */
    const handleRefresh = () => {
        setRefresh(!refresh);
    };

    /**
     * 6133415942-Function for handling date range
     * @param {*} start
     * @param {*} end
     */
    const handleDateRange = (start, end) => {
        setFilter({
            ...filter,
            // 6367053025 - Generic Date Filter bug changes
            InvoiceDiscountCodeChargebackStartDate: start,
            InvoiceDiscountCodeChargebackEndDate: end
        });
        addURLParam(window, "InvoiceDiscountCodeChargebackStartDate", start);
        addURLParam(window, "InvoiceDiscountCodeChargebackEndDate", end);
    };

    /**
     * 6133415942-Handler for clear all
     */
    const handleClearAll = () => {
        setClearAll(false);
        removeAllURLParams();
        clearFilterState();
    };

    /**
     * 6133415942-Remove Badge Handler Function to pass
     * @param {*} filter
     * @param {*} setFilter
     * @returns
     */
    const createRemoveBadgeWrapper = (filter, setFilter) => e => {
        removeBadge(
            e,
            filter,
            setFilter,
            () => {},
            () => {},
            ""
        );
    };

    /**
     * 6133415942-Handler for Invoice Status Filter
     * @param {*} e
     */
    const handleInvoiceStatus = e => {
        let checkbox = [...new Set(filter.invoiceStatus)];

        if (e.target.checked) {
            checkbox.push({ value: e.target.value, label: e.target.dataset.label });
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value);
        }

        setFilter({ ...filter, invoiceStatus: checkbox });
    };
    const urlSearchParams = useLocation().search;
    React.useMemo(() => {
        if (filter.urlParamsProcessed === false) processURLSearchParams(new URLSearchParams(urlSearchParams), filter, setFilter);
    }, [urlSearchParams]);
    return (
        <>
            <Container fluid>
                <div className={"pt-7"}>
                    <Row className={"mb-2"}>
                        <Col xs={8} lg={2} className={"text-left"}></Col>
                        <Col className={"text-right"}>
                            <div className={"d-none d-lg-inline-block mr-2"}>
                                <button className="btn btn-neutral btn-sm p-9px" onClick={handleRefresh}>
                                    <i className="fas fa-sync-alt"></i>
                                </button>
                            </div>
                            <Filter
                                name={"Brand"}
                                prefix={"discountCodeBrand"}
                                onChange={e => onFilterChange(e, "discountCodeBrand", filter, setFilter)}
                                newparams={params}
                                selected={filter.discountCodeBrand}
                                url={"/discount/filter/brand/"}
                                onClear={() => clearFilter("discountCodeBrand", setFilter, setClearAll)}
                            />
                            <Filter
                                name={"Invoice Number"}
                                prefix={"invoiceNumbers"}
                                onChange={e => onFilterChange(e, "invoiceNumbers", filter, setFilter)}
                                newparams={params}
                                selected={filter.invoiceNumbers}
                                url={"discount-code-chargeback/filter/invoice-number"}
                                onClear={() => clearFilter("invoiceNumbers", setFilter, setClearAll)}
                            />
                            <Filter
                                name={"Invoice Status"}
                                prefix={"invoice_status"}
                                onChange={handleInvoiceStatus}
                                newparams={params}
                                selected={filter.invoiceStatus}
                                url={"discount-code-chargeback/filter/invoice-status"}
                                onClear={() => clearFilter("invoice_status")}
                            />
                            {/**7114901778- Store Filters on Invoices chargebacks*/}
                            <Filter
                                name={"Store"}
                                prefix={"store"}
                                onChange={e => onFilterChange(e, "store", filter, setFilter)}
                                newparams={params}
                                selected={filter.store}
                                url={"order/shipping-chargebacks/filter/store"}
                                onClear={() => clearFilter("store", setFilter, setClearAll)}
                            />
                            <DateRange onCallback={handleDateRange} button_name={"Invoice Date"}></DateRange>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <div className="col">
                        <Card className="shadow ">
                            <CardHeader className="border-0">
                                <Row className="d-flex justify-content-between align-items-center p-1">
                                    <div className="d-flex">
                                        <h3 className="mb-0">
                                            Discount Code Chargeback Invoices <small>({discountCodeInvoicesCount})</small>
                                        </h3>
                                        {clearall > 0 && (
                                            <a
                                                href={void 0}
                                                className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                                                onClick={handleClearAll}
                                                id="clear_all_filters">
                                                <i className="fas fa-times-circle"></i> Clear All filters
                                            </a>
                                        )}
                                    </div>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <BadgePillFilter data={filter} onClick={createRemoveBadgeWrapper(filter, setFilter)} />
                                    </Col>
                                </Row>
                            </CardHeader>
                            {isLoading ? (
                                <div className="text-center mt-3 mb-3">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col" className="pl-5">
                                                    <Sort onClick={() => handleSort(sort, "invoice_ref", setSortOrder, setSort)}>Invoice</Sort>
                                                </th>
                                                <th scope="col" className="pl-5">
                                                    Brand
                                                </th>
                                                <th scope="col" className="pl-5">
                                                    <Sort onClick={() => handleSort(sort, "collect_payment", setSortOrder, setSort)}>Invoice Date</Sort>
                                                </th>
                                                <th scope="col" className="">
                                                    <Sort onClick={() => handleSort(sort, "total", setSortOrder, setSort)}>Total($)</Sort>
                                                </th>
                                                <th scope="col" className="pl-5">
                                                    <Sort onClick={() => handleSort(sort, "status", setSortOrder, setSort)}>Status</Sort>
                                                </th>
                                                <th scope="col" className="">
                                                    Stripe Receipt Link
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {discountCodeInvoices.map(discountCodeInvoice => (
                                                <tr>
                                                    <td className="pl-5">
                                                        {discountCodeInvoice?.invoice_ref &&
                                                        discountCodeInvoice?.invoice_ref !== "" &&
                                                        discountCodeInvoice?.invoice_ref !== null ? (
                                                            <a href={"/discount-code-chargebacks?invoiceNumbers=" + discountCodeInvoice?.invoice_ref}>
                                                                {discountCodeInvoice?.invoice_ref}
                                                            </a>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </td>
                                                    {/* <a
                                                        href={"/discount-code-chargebacks?invoiceNumbers=" + discountCodeInvoice?.invoice_ref}
                                                        className="btn btn-link p-0">
                                                        <td className="pl-5">{discountCodeInvoice?.invoice_ref ? discountCodeInvoice?.invoice_ref : "-"}</td>
                                                    </a> */}
                                                    <td
                                                        className="pl-5"
                                                        style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "200px" }}
                                                        >
                                                        {discountCodeInvoice?.brands.length === 0 ? "-" : discountCodeInvoice?.brands.join(", ")}
                                                    </td>
                                                    <td className="pl-5">
                                                        {discountCodeInvoice?.created_at ? (
                                                            <Moment format="MM/D/YYYY" withTitle>
                                                                {discountCodeInvoice?.created_at}
                                                            </Moment>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </td>
                                                    <td className="">{discountCodeInvoice?.total || "-"}</td>
                                                    <td className="pl-5">{discountCodeInvoice?.status || "-"}</td>
                                                    <td className="">
                                                        {discountCodeInvoice?.invoice_domain !== null && discountCodeInvoice?.invoice_number !== "" ? (
                                                            <a
                                                                href={discountCodeInvoice?.invoice_domain + "/" + discountCodeInvoice?.invoice_number}
                                                                className="btn btn-link p-0">
                                                                <i className={"fas fa-link"}></i>
                                                            </a>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <CardFooter className="text-center">
                                        <nav aria-label="...">
                                            {pagination.pages().length > 1 && (
                                                <Pagination className="pagination justify-content-center mb-0" listClassName="justify-content-center mb-0">
                                                    <PaginationItem className="">
                                                        <PaginationLink href="#" onClick={e => handlePagination(pagination?.previous)} tabIndex="-1">
                                                            <i className="fas fa-angle-left" />
                                                            <span className="sr-only">Previous</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                    {pagination.pages().map((pageIndex, index) => (
                                                        <PaginationItem key={index} className={page === pageIndex ? "active" : ""}>
                                                            <PaginationLink href="#" onClick={e => handlePagination(pageIndex)}>
                                                                {pageIndex}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    ))}
                                                    <PaginationItem>
                                                        <PaginationLink href="#" onClick={e => handlePagination(pagination?.next)}>
                                                            <i className="fas fa-angle-right" />
                                                            <span className="sr-only">Next</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                </Pagination>
                                            )}
                                        </nav>
                                    </CardFooter>
                                </>
                            )}
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default DiscountCodeChargebacksInvoice;
