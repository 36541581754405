import BadgePillFilter from 'components/Filters/Badge';
import DateRange from 'components/Filters/DateRange';
import Filter from 'components/Filters/Filter';
import { prefixAndFilterMapping } from 'customHooks/useFilter';
import moment from 'moment';
import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications';
import { ButtonDropdown, Col, Container, DropdownToggle, Row, DropdownMenu, DropdownItem, Input } from 'reactstrap';
import { api } from 'services/api';
import { removeBadge } from 'utils/commonFunctions';
import { removeURLParam } from 'utils/urls';
import { addURLParam } from 'utils/urls';
import DashboardMoreFilters from './dashboardMoreFilters';
import fileDownload from "js-file-download";
import { getParams } from 'utils/commonFunctions';
import OrdersExport from 'feature/common/orders-export/OrdersExport';

const DashboardFilters = ({ filter, setFilter }) => {
    const { addToast } = useToasts();
    const [params, setParams] = useState([]);
    const [clearall, setClearAll] = useState(false);
    const [selected, setSelected] = useState([]);
    const [toggleRefresh, settoggleRefresh] = useState(false);
    const [exportDropdownOpen, setExportDropdownOpen] = useState(false);
    const [exportButton, setExportButton] = useState({ label: "Export", disabled: false });
    const [autoRefresh, setautoRefresh] = useState(false)

    const filters = [
        {
            name: "Assignee",
            prefix: "assignees",
            onChange: (e, prefix) => filterOnChangeHandler(e, "assignee"),
            newparams: params,
            selected: filter.assignees,
            url: "order/filter/assignee/",
            inputSearch: true,
            onClear: () => { clearFilter("assignee") },
        },
        {
            name: "Products",
            prefix: "products",
            onChange: (e, prefix) => filterOnChangeHandler(e, "product"),
            newparams: params,
            selected: filter.products,
            url: "/order/filter/product/",
            inputSearch: true,
            onClear: () => { clearFilter("product") },
        },
        {
            name: "Brands",
            prefix: "brands",
            onChange: (e, prefix) => filterOnChangeHandler(e, "brand"),
            newparams: params,
            selected: filter.brands,
            url: "/order/filter/brand/",
            inputSearch: true,
            onClear: () => { clearFilter("brand") },
        },
        {
            name: "Pre-Orders",
            prefix: "preOrders",
            onChange: (e, prefix) => filterOnChangeHandler(e, "preOrders"),
            newparams: params,
            selected: filter.preOrders,
            // TODO: @AW - 23-04-2024 Please add URL for filter options
            url: "/order/filter/pre-orders",
            inputSearch: true,
            onClear: () => { clearFilter("preOrders") },
        }
    ]

    const clearFilter = (prefix) => {

        // Clear filter.prefix state
        if (prefix === "brand") {
            setFilter({ ...filter, brands: [], });
        } else if (prefix === "product") {
            setFilter({ ...filter, products: [], });
        } else if (prefix === "assignee") {
            setFilter({ ...filter, assignees: [], });
        } else if (prefix === "preOrders") {
            setFilter({ ...filter, preOrders: [], });
        }

        // Remove URL param from URL
        removeURLParam(window, prefixAndFilterMapping[prefix]);
        setClearAll(false);

        // Untick checkboxes
        let checkbox = document.querySelectorAll(".checkbox_" + prefix + ":checked");
        if (checkbox.length > 0) {
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = false;
            }
        }
    };

    const handleDateRange = (start, end) => {
        setFilter({ ...filter, startDate: start, endDate: end, });
        addURLParam(window, "startDate", start);
        addURLParam(window, "endDate", end);
    }

    const filterOnChangeHandler = (e, prefix) => {
        if (e === "__all__") {
            let filteredRecords = [];
            let checkbox = document.querySelectorAll(`.checkbox_${prefix}`);
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                filteredRecords.push({ value: checkbox[i].value, label: checkbox[i].dataset.label, });
                addURLParam(window, prefixAndFilterMapping[prefix], checkbox[i].value);
            }
            let updatedFilter = { ...filter };
            updatedFilter[prefix] = filteredRecords;
            setFilter(updatedFilter);
        } else {
            let filteredRecords = [...new Set(filter[`${prefixAndFilterMapping[prefix]}`])];
            if (e.target.checked) {
                filteredRecords.push({ value: e.target.value, label: e.target.dataset.label });
                addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
            } else {
                filteredRecords = filteredRecords.filter(item => item.value !== e.target.value);
                removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
            }
            let updatedFilter = { ...filter };
            updatedFilter[prefixAndFilterMapping[prefix]] = filteredRecords;
            setFilter(updatedFilter);
        }
    };

    const handleExport = ((url, fileNamePrefix) => {
        setExportButton({ label: "Exporting...", disabled: true, });
        let new_params = getParams(filter);
        api.get(url, { params: new_params, })
            .then((response) => response.data)
            .then((response) => {
                const success = response?.success | false;
                if (success) {
                    addToast(response.message, { appearance: "success", autoDismiss: false });
                } else {
                    const timestamp = moment().format("MMMM-Do-YYYY-h:mm:ss-a");
                    const filename = fileNamePrefix + timestamp + ".csv";
                    fileDownload(response, filename);
                }
                setExportButton({ label: "Export", disabled: false });
            });
    });

    // Refresh page after every two minutes, 2mins == 120000 milisecond
    if (autoRefresh) { setTimeout(() => { window.location.reload() }, 120000); }

    return (
        <Container fluid>
            <Row className='d-flex-row-reverse justify-content-end align-items-top m-0'>
                <Col className='col-xl-4 col-lg-12 col-md-12 col-sm-12 p-0 m-0'>
                    <BadgePillFilter backgroundColor="white" textColor="primary" data={filter} selected={selected}
                        onClick={(e) => { removeBadge(e, filter, setFilter, selected, setSelected, setClearAll) }} />
                </Col>

                {/* Filters : To add new filter, add required data in filters variable */}

                <Col className='col-auto col-xl-8 col-lg-12 col-md-12 col-sm-12 p-0 m-0 d-flex flex-row-lg-reverse align-items-center justify-content-end mt-xl-0 mt-lg-0 mt-md-3 mt-sm-3'>
                    {/* Feature : Auto refresh every 2 minutes */}
                    <Col className='col-auto flex-row align-items-center d-none d-md-flex'>
                        <Input id="big-checkbox" className='mr-2' type="checkbox" name="autoRefresh" value={autoRefresh} onChange={() => { setautoRefresh(!autoRefresh) }} />
                        <span className='text-white font-size-small mx-2 mt-1'>Auto refresh every 2 minutes </span>
                    </Col>

                    {/* Refresh Button */}
                    <button className="btn btn-neutral btn-sm p-9px" onClick={() => { setFilter({ ...filter }) }}><i className="fas fa-sync-alt"></i></button>

                    {/* Filters */}
                    {filters.map((filterParam) => {
                        return (
                            <Filter
                                className='d-none d-lg-block'
                                name={filterParam.name}
                                prefix={filterParam.prefix}
                                onChange={filterParam.onChange}
                                newparams={filterParam.params}
                                selected={filterParam.selected}
                                url={filterParam.url}
                                inputSearch={filterParam.inputSearch}
                                onClear={filterParam.onClear}
                            />
                        )
                    })}

                    {/* Date range filter */}
                    <div className='d-none d-lg-block'>
                        <DateRange startDate={filter.startDate} endDate={filter.endDate} onCallback={handleDateRange}></DateRange>
                    </div>

                    {/* Export Button */}
                    <OrdersExport 
                        exportButton={exportButton} 
                        setExportButton={setExportButton} 
                        exportDropdownOpen={exportDropdownOpen} 
                        setExportDropdownOpen={setExportDropdownOpen} 
                        getParams={getParams}
                        filter={filter}
                    />

                    <DashboardMoreFilters filter={filter} setFilter={setFilter} />
                </Col>
            </Row>
        </Container>
    )
}

export default DashboardFilters
